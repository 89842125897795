import { useState } from "react";
// @mui
import { TableRow, TableCell, IconButton, Checkbox, TableCellProps, Tooltip } from "@mui/material";

// import { Link as RouterLink } from "react-router-dom";

import { matchPath, useLocation, useNavigate, useParams } from "react-router";

import { OrgTicketsResponse } from "src/@types/profile";
import Label from "src/components/label/Label";

// utils
import { fDate } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
// import ConfirmDialog from "../../../components/confirm-dialog";

// ----------------------------------------------------------------------
type IRow = OrgTicketsResponse["tickets"]["rows"]["0"];
type Props = {
  row: IRow;
  disableSelection: boolean;
  selected: boolean;

  headLabel: {
    id: string;
    label: string;
    align: string;
  }[];
  onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
};

export default function OrgTicketsTableRow({
  row,
  headLabel,
  disableSelection,
  selected,
  onSelectRow,
}: // onViewRow,
Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { row_id, ticket_state, ticket_action } = row;
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    handleOpenConfirm();
    handleCloseConfirm();
  };

  const handleClosePopover = () => {
    setOpenPopover(openConfirm ? null : null);
  };
  const isOrgEventsRoute = matchPath("/dashboard/orgs/:orgId/*", pathname);
  // const ticketDetailPagePath = orgId && row_id ? `/dashboard/orgs/${orgId}/tickets/${row_id}` : "";
  // eslint-disable-next-line no-nested-ternary
  const ticketDetailPagePath = orgId
    ? isOrgEventsRoute
      ? `/dashboard/orgs/${orgId}/tickets/${row_id}`
      : `${row_id}`
    : "";
  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          {ticket_action === "NONE" ? null : (
            <Checkbox
              checked={selected}
              onClick={onSelectRow}
              disabled={disableSelection || ticket_action === "NONE"}
            />
          )}
        </TableCell>

        {headLabel.map((head) => {
          const key = head.id as keyof IRow;

          if (key === "ticket_action") {
            if (row[key] === "NONE") {
              return <TableCell key={key} align="center" />;
            }
            return (
              <TableCell key={key} align="center">
                <Tooltip title={row[key]} arrow>
                  <Iconify
                    icon={
                      row[key] === "RECLAIM"
                        ? "ic:outline-double-arrow"
                        : "ph:arrow-bend-double-up-right-bold"
                    }
                    sx={
                      row[key] === "RECLAIM"
                        ? { transform: "rotate(180deg)", color: "red" }
                        : { color: "green" }
                    }
                  />
                </Tooltip>
              </TableCell>
            );
          }
          const val = key.includes("date") ? fDate(row[key]) : row[key] ?? "-";
          if (key === "ticket_state") {
            return (
              <TableCell
                onClick={() => {
                  if (ticketDetailPagePath) {
                    navigate(ticketDetailPagePath, {
                      state: {
                        holderId: row_id,
                      },
                    });
                  }
                }}
                key={key}
                align="left"
              >
                <Label
                  variant="soft"
                  color={
                    (ticket_state?.toLowerCase() === "delivered" && "success") ||
                    (ticket_state?.toLowerCase() === "undelivered" && "error") ||
                    "default"
                  }
                >
                  {ticket_state}
                </Label>
              </TableCell>
            );
          }
          return (
            <TableCell
              onClick={() => {
                if (ticketDetailPagePath) {
                  navigate(ticketDetailPagePath, {
                    state: {
                      holderId: row_id,
                    },
                  });
                }
              }}
              key={key}
              align={head.align as TableCellProps["align"]}
            >
              {val}
            </TableCell>
          );
        })}

        <TableCell align="right">
          <IconButton color={openPopover ? "inherit" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        {/* <MenuItem
          onClick={() => {
            // onViewRow();
            // handleClosePopover();
            navigate(`/dashboard/orgs/${orgId}/tickets`, {
              state: {
                eventId: row_id,
              },
            });
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View Tickets
        </MenuItem>

        <MenuItem
          onClick={() => {
            // onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View Ticket Holder
        </MenuItem> */}
      </MenuPopover>
    </>
  );
}
