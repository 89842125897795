import { FC } from "react";
import TicketProfileWrapper from "src/pages/dashboard/ticket/TicketProfileWrapper";
import { useOrgTicketQuery } from "src/hooks/api/org";
import { useParams } from "react-router";
import { CircularProgress, Typography, Grid, Stack, TextField } from "@mui/material";
import { Block } from "src/components/Block";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";

const Ticket: FC = () => {
  const { orgId, ticketId } = useParams();
  const { data, isLoading, isPreviousData } = useOrgTicketQuery(orgId || "", ticketId || "");
  const activityLen = data?.activity?.length || 0;

  if (isLoading && !isPreviousData) {
    return <CircularProgress />;
  }
  if (data?.success === false) {
    return (
      <Typography variant="h4" fontStyle="italic">
        {data?.display_message}
      </Typography>
    );
  }

  return (
    <div>
      <TicketProfileWrapper
          name={`${data?.forename} ${data?.surname}`}
          cover={data?.background_image_url || ""}
          tagline={data?.tagline || ""}
          photo_url={data?.photo_url || ""}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Stack spacing={3}>
              {data?.property.length
                ? data.property.map((item, i) => {
                    return <TextField label={`${item.key}`} disabled value={`${item.value}`} />;
                  })
                : null}
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Block title="Activity">
              <Timeline position="alternate">
                {data?.activity?.length
                  ? data?.activity.map((item, i) => (
                      <TimelineItem key={i}>
                        <TimelineOppositeContent>
                          <Typography sx={{ color: "text.secondary" }}>{item.date}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot sx={{ backgroundColor: item.colour }} />
                          {activityLen - 1 === i ? null : <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography> {item.name}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))
                  : null}
              </Timeline>
            </Block>
          </Grid>
        </Grid>
      </TicketProfileWrapper>
    </div>
  );
};

export default Ticket;
