import { CircularProgress } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuthContext } from "src/auth/useAuthContext";

function withOrgDefaultRedirection<P>(Component: FC<P>, path = "overview") {
  // @ts-ignore
  function Wrapper(props: P & JSX.IntrinsicAttributes) {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const { user } = useAuthContext();
    const default_org_id = user?.organisation_id_default;

    useEffect(() => {
      if (default_org_id && orgId === "default") {
        navigate(`/dashboard/orgs/${default_org_id}/${path}`);
      }
    }, [default_org_id, orgId, navigate]);

    if (orgId === "default") {
      return <CircularProgress />;
    }

    return (
      <>
        {/* <BackButton /> */}
        <Component {...props} default_org_id={default_org_id} />
      </>
    );
  }
  return Wrapper;
}

export default withOrgDefaultRedirection;
