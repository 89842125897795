import React, { ReactElement } from "react";

function IfHasValue({ value, children }: { value: any; children?: ReactElement }) {
  if (value == null || value == undefined) {
    return null;
  }

  return <>{children}</>;
}

export default IfHasValue;
