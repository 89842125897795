import { Helmet } from "react-helmet-async";
import React, { ChangeEvent } from "react";
// import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  // Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Typography,
  Chip,
} from "@mui/material";

// routes
import { useOrgStore } from "src/global-stores/useOrgStore";

// components
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  useTable,
  // getComparator,
  // emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "src/components/table";
// sections
import { TBaseColumn, TBaseQueryParam, TBaseRecord, TBaseResponse } from "src/@types/api";
import TableRow from "./Row";

// ----------------------------------------------------------------------

type Props = {
  responseData: TBaseResponse;
  tableData?: TBaseRecord;

  tabs: {
    value: string;
    label: string;
  }[];
  currentTab: string;
  heading: string;
  queryParamState: TBaseQueryParam;
  isLoading: boolean;
  showCreateHeader?: boolean;

  handleFilterQueryParams: (obj: Partial<TBaseQueryParam>) => void;
  toolbarComp?: React.ReactElement;
};

// ----------------------------------------------------------------------

export default function OrgBaseTable({
  responseData,
  tableData,
  tabs: TABS,
  currentTab,
  queryParamState,
  toolbarComp,
  heading,
  isLoading,
  showCreateHeader = true,
  handleFilterQueryParams,
}: Props) {
  const { event: storedEvent, setStoreEvent } = useOrgStore();

  const tableDataRows = tableData?.rows || [];
  const isNotFound =
    !tableDataRows.length ||
    (tableDataRows.length && tableDataRows.length === 1 && !tableDataRows[0]?.row_id);
  const TABLE_HEAD = [
    ...(tableData?.columns?.map((clm: TBaseColumn) => ({
      id: clm?.column_id,
      label: clm?.title,
      align: clm?.alignment === "CENTRE" ? "center" : clm?.alignment?.toLowerCase(),
    })) || []),

    ...(!isNotFound ? [{ id: "action", label: "", align: "right" }] : []),
  ];

  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    // setPage,
    selected,
    // setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: queryParamState.page,
    defaultRowsPerPage: queryParamState.limit,
  });

  const denseHeight = dense ? 56 : 76;
  return (
    <>
      <Helmet>
        <title>{heading} | TX.IS</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        {showCreateHeader ? (
          <Stack direction="row" justifyContent="space-between" mb={4}>
            <Typography variant="h3">
              <span>{heading}</span>
              {storedEvent && (
                <Chip
                  sx={{ ml: 1 }}
                  label={`Event: ${storedEvent.name}`}
                  onDelete={() => setStoreEvent(undefined)}
                />
              )}
            </Typography>
          </Stack>
        ) : null}

        <Card>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => {
              handleFilterQueryParams({
                page: 0,
              });
            }}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <Divider />

          {/* Search / date pick-->>>> */}
          {toolbarComp}

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableDataRows.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableDataRows.map((row: any) => row.row_id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={() => {}}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableDataRows.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  showCheckbox={false}
                />

                <TableBody>
                  {!isLoading &&
                    !isNotFound &&
                    tableDataRows.map((row: any, key: number) => (
                      <TableRow
                        headLabel={TABLE_HEAD.slice(0, -1)}
                        key={row.row_id}
                        row={row}
                        selected={selected.includes(row.row_id)}
                        onSelectRow={() => onSelectRow(row.row_id)}
                        // onViewRow={() => handleViewRow(row.id)}
                      />
                    ))}
                  {isLoading && (
                    <TableEmptyRows isLoading height={denseHeight} emptyRows={rowsPerPage} />
                  )}
                  <TableEmptyRows height={denseHeight} emptyRows={Number(isNotFound)} />

                  <TableNoData isNotFound={!!isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableData?.total_records || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(evt, newpage) => {
              onChangePage(evt, newpage);
              handleFilterQueryParams({
                page: Number(newpage),
              });
            }}
            onRowsPerPageChange={(evt: ChangeEvent<HTMLInputElement>) => {
              onChangeRowsPerPage(evt);
              handleFilterQueryParams({
                limit: Number(evt.target.value),
                page: 0,
              });
            }}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
