import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Tab, Tabs, Box, Typography } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import Iconify from "src/components/iconify";
import AccountGeneral from "src/sections/user/general-settings";
import UpdatePassword from "src/sections/user/change-password";

const EditUserPage: FC = () => {
  const { themeStretch } = useSettingsContext();
  const [currentTab, setCurrentTab] = useState("general");

  const TABS = [
    {
      value: "general",
      label: "General",
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },

    {
      value: "change_password",
      label: "Change password",
      icon: <Iconify icon="ic:round-vpn-key" />,
      component: <UpdatePassword />,
    },
  ];

  return (
    <>
      <Helmet>
        <title> Your Profile | TX.IS</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h3">Your Profile</Typography>
        <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </>
  );
};

export default EditUserPage;
