// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon("ic_user"),
  file: icon("ic_file"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  label: icon("ic_label"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "you",
    items: [
      { title: "Ticket Wall", path: PATH_DASHBOARD.user.ticketwall, icon: ICONS.user },
      { title: "Friends", path: PATH_DASHBOARD.user.friends, icon: ICONS.file },
      { title: "Organisations", path: PATH_DASHBOARD.user.orgs, icon: ICONS.analytics },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "organisation",
    items: [
      {
        title: "Overview",
        path: PATH_DASHBOARD.orgs.overview,
        icon: ICONS.label,
        pattern: "/dashboard/orgs/:orgId/overview",
        defaultParamValues: { orgId: "default" },
      },
      {
        title: "Events",
        path: PATH_DASHBOARD.orgs.events,
        icon: ICONS.analytics,
        pattern: "/dashboard/orgs/:orgId/events/*",
        defaultParamValues: { orgId: "default" },
      },
      {
        title: "All Tickets",
        path: PATH_DASHBOARD.orgs.tickets,
        icon: ICONS.file,
        pattern: "/dashboard/orgs/:orgId/tickets/*",
        defaultParamValues: { orgId: "default" },
      },
      {
        title: "All Ticket Holders",
        path: PATH_DASHBOARD.orgs.ticketholder,
        icon: ICONS.user,
        pattern: "/dashboard/orgs/:orgId/ticket-holders/*",
        defaultParamValues: { orgId: "default" },
      },
    ],
  },
];

export default navConfig;
