// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import { useOrgOverviewQuery } from "src/hooks/api/org";
import { matchPath, useLocation, useParams } from "react-router";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const { orgId } = useParams();
  const { data } = useOrgOverviewQuery(
    `${orgId && orgId !== "default" ? orgId : user?.organisation_id_default}`
  );

  const orgsPagePatternActive = !!matchPath("/dashboard/orgs/:orgId/*", pathname);
  if (data?.success && orgsPagePatternActive) {
    return (
      <ProfileWrapper
        name={data?.organisation_name}
        avatarUrl={data?.logo_url}
        urole={data?.organisation_tagline}
      />
    );
  }
  return (
    <ProfileWrapper
      name={`${user?.forename} ${user?.surname}`}
      avatarUrl={user?.photo_url}
      urole={user?.role}
    />
  );
}
interface TProfielWrapper {
  avatarUrl?: string;
  name?: string;
  urole?: string;
}
const ProfileWrapper = ({ name = "", avatarUrl = "", urole = "" }: TProfielWrapper) => {
  return (
    <StyledRoot>
      <CustomAvatar src={avatarUrl} alt={name} name={name} />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
          {urole}
        </Typography>
      </Box>
    </StyledRoot>
  );
};
