// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_AUTH = "/secure";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  secure: {
    password: {
      reset: path(ROOTS_AUTH, "/password/reset"),
      update: path(ROOTS_AUTH, "/password/update"),
    },
  },
  register: {
    root: "/register",
    confirm: "/register/confirm",
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    ticketwall: path(ROOTS_DASHBOARD, "/profile/ticket-wall"),
    friends: path(ROOTS_DASHBOARD, "/profile/friends"),
    orgs: path(ROOTS_DASHBOARD, "/profile/orgs"),
  },
  orgs: {
    overview: path(ROOTS_DASHBOARD, "/orgs/default/overview"),
    events: path(ROOTS_DASHBOARD, "/orgs/default/events"),
    tickets: path(ROOTS_DASHBOARD, "/orgs/default/tickets"),
    ticketholder: path(ROOTS_DASHBOARD, "/orgs/default/ticket-holders"),
  },
};
