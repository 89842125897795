// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Toaster } from "react-hot-toast";

// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryParamProvider } from "use-query-params";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";

import "./global.css";
// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from "./auth/JwtContext";
import { REACT_QUERY_DEFAULT_CONFIG } from "./config-global";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: REACT_QUERY_DEFAULT_CONFIG.STALE_TIME,
    },
  },
});
// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <QueryClientProvider client={queryClient}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Router />
                            <Toaster />
                          </LocalizationProvider>
                          <ReactQueryDevtools />
                        </QueryClientProvider>
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </QueryParamProvider>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
