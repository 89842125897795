// @mui
import { TableRow, TableCell, CircularProgress } from "@mui/material";

// ----------------------------------------------------------------------

type Props = {
  height?: number;
  emptyRows: number;
  isLoading?: boolean;
};

export default function TableEmptyRows({ emptyRows, height, isLoading }: Props) {
  if (!emptyRows) {
    return null;
  }

  return (
    <TableRow
      sx={{
        // ...(height && {
        //   height: height * (emptyRows - 1),
        // }),
        height: 56 * 1,
      }}
    >
      {isLoading ? (
        <TableCell colSpan={9} sx={{ textAlign: "start" }}>
          <CircularProgress />
        </TableCell>
      ) : (
        <TableCell colSpan={9} />
      )}
    </TableRow>
  );
}
