// @mui
import { styled } from "@mui/material/styles";
import { Link, Card, Typography, CardHeader, Stack } from "@mui/material";
// @types
import { IUserProfileAbout } from "../../../../../@types/user";
// components
import Iconify from "../../../../../components/iconify";
import IfHasValue from "src/components/has-value";

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ProfileAbout({
  quote,
  country,
  email,
  role,
  company,
  school,
}: IUserProfileAbout) {
  return (
    <Card>
      <CardHeader title="About" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <IfHasValue value={quote}>
          <Typography variant="body2">{quote}</Typography>
        </IfHasValue>

        <IfHasValue value={country}>
          <Stack direction="row">
            <StyledIcon icon="eva:pin-fill" />

            <Typography variant="body2">
              Live at &nbsp;
              <Link component="span" variant="subtitle2" color="text.primary">
                {country}
              </Link>
            </Typography>
          </Stack>
        </IfHasValue>

        <IfHasValue value={email}>
          <Stack direction="row">
            <StyledIcon icon="eva:email-fill" />
            <Typography variant="body2">{email}</Typography>
          </Stack>
        </IfHasValue>

        <IfHasValue value={company}>
          <Stack direction="row">
            <StyledIcon icon="ic:round-business-center" />

            <Typography variant="body2">
              {role} at &nbsp;
              <Link component="span" variant="subtitle2" color="text.primary">
                {company}
              </Link>
            </Typography>
          </Stack>
        </IfHasValue>

        <IfHasValue value={school}>
          <Stack direction="row">
            <StyledIcon icon="ic:round-business-center" />

            <Typography variant="body2">
              Studied at &nbsp;
              <Link component="span" variant="subtitle2" color="text.primary">
                {school}
              </Link>
            </Typography>
          </Stack>
        </IfHasValue>
      </Stack>
    </Card>
  );
}
