import React from "react";
import OrgTickets from "../OrgTickets";

const EventTicket = () => {
  return (
    <div>
      <OrgTickets showHeader={false} />
    </div>
  );
};

export default EventTicket;
