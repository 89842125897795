import { useState } from "react";
import * as Yup from "yup";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

// auth
import { useAuthContext } from "src/auth/useAuthContext";
// components
import Iconify from "src/components/iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const location = useLocation();
  const { login } = useAuthContext();

  const prefilledEmail = location.state?.email ? location.state?.email : "";
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: import.meta.env.DEV ? import.meta.env.REACT_APP_EMAIL : prefilledEmail || "",
    // email: prefilledEmail || "",
    password: import.meta.env.DEV ? import.meta.env.REACT_APP_PW : "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    control,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      setError("afterSubmit", {
        ...error,
        message: error.error.message || error,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              onChange={(e) => {
                field.onChange(e.target.value.trim());
              }}
              value={typeof field.value === "number" && field.value === 0 ? "" : field.value}
              error={!!error}
              helperText={error ? error?.message : ""}
              label="Email address"
              disabled={!!prefilledEmail}
            />
          )}
        />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          variant="body2"
          color="inherit"
          underline="always"
          to="/secure/password/reset"
          state={{ email: defaultValues.email }}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: "text.primary",
          color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
          },
        }}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
