import { useState } from "react";
// @mui
import {
  Link,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  TableCellProps,
  Tooltip,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Link as RouterLink } from "react-router-dom";

import { useNavigate, useParams } from "react-router";
import { useOrgStore } from "src/global-stores/useOrgStore";

import { OrgEventsResponse } from "src/@types/profile";
// utils
import { fDate } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
// import ConfirmDialog from "../../../components/confirm-dialog";

// ----------------------------------------------------------------------
type IRow = OrgEventsResponse["events"]["rows"]["0"];
type Props = {
  row: IRow;
  headLabel: {
    id: string;
    label: string;
    align: string;
  }[];
  selected: boolean;
  onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
};

export default function OrgEventTableRow({
  row,
  headLabel,
  selected,
  onSelectRow,
}: // onViewRow,
Props) {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { setStoreEvent } = useOrgStore();

  const { event_name, row_id } = row;
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    handleOpenConfirm();
    handleCloseConfirm();
  };

  const handleClosePopover = () => {
    setOpenPopover(openConfirm ? null : null);
  };

  return (
    <>
      <TableRow hover>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        {headLabel.map((head) => {
          const key = head.id as keyof IRow;

          if (head.id === "ticket-action") {
            return null;
          }
          const val = key.includes("date") ? fDate(row[key]) : row[key];

          return (
            <TableCell
              onClick={() => {
                // navigate(`/dashboard/orgs/${orgId}/tickets`, {
                //   state: {
                //     eventId: row_id,
                //     eventName: event_name,
                //   },
                // });
                navigate(`/dashboard/orgs/${orgId}/events/${row_id}/overview`, {
                  state: {
                    eventId: row_id,
                    eventName: event_name,
                  },
                });
              }}
              key={key}
              align={head.align as TableCellProps["align"]}
            >
              {val}
            </TableCell>
          );
        })}

        <TableCell align="right">
          <Tooltip title="View Tickets">
            <Link
              component={RouterLink}
              to={`/dashboard/orgs/${orgId}/events/${row_id}/tickets`}
              state={{ eventId: row_id, eventName: event_name }}
              onClick={() => {
                setStoreEvent({ id: row_id, name: event_name });
              }}
            >
              <ConfirmationNumberIcon />
            </Link>
          </Tooltip>
        </TableCell>

        <TableCell align="right">
          <Tooltip title="View Ticket Holders">
            <Link
              component={RouterLink}
              to={`/dashboard/orgs/${orgId}/events/${row_id}/ticket-holders`}
              state={{
                eventId: row_id,
                eventName: event_name,
              }}
              onClick={() => {
                setStoreEvent({ id: row_id, name: event_name });
              }}
            >
              <PeopleOutlineIcon />
            </Link>
          </Tooltip>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? "inherit" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem
          onClick={() => {
            // onViewRow();
            // handleClosePopover();
            navigate(`/dashboard/orgs/${orgId}/events/${row_id}/tickets`, {
              state: {
                eventId: row_id,
                eventName: event_name,
              },
            });
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View Tickets
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/dashboard/orgs/${orgId}/events/${row_id}/ticket-holders`, {
              state: {
                eventId: row_id,
                eventName: event_name,
              },
            });
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View Ticket Holders
        </MenuItem>
      </MenuPopover>
    </>
  );
}
