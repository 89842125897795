import { Link as RouterLink, useParams } from "react-router-dom";
// @mui
import { Box, Card, Avatar, Typography, CardContent, Link } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { useOrgStore } from "src/global-stores/useOrgStore";

// routes
// import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// utils
// import { fDate } from "../../../utils/formatTime";
// @types
import { TOrgEvent } from "../../../@types/organisation";
// components
import Image from "../../../components/image";
import TextMaxLine from "../../../components/text-max-line";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = TOrgEvent & {
  index?: number;
};

export default function EventCard({
  event_id,
  event_name,
  event_image_url,
  organisation_image_url,
  event_date,
  index,
}: Props) {
  return (
    <Card>
      <Box sx={{ position: "relative" }}>
        <SvgColor
          src="/assets/shape_avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: "absolute",
            color: "background.paper",
          }}
        />

        <Avatar
          src={organisation_image_url}
          sx={{
            left: 24,
            zIndex: 9,
            width: 32,
            height: 32,
            bottom: -16,
            position: "absolute",
          }}
        />

        <Image alt="cover" src={event_image_url} ratio="4/3" />
      </Box>

      <Content title={event_name} event_id={event_id} event_date={event_date} />
    </Card>
  );
}

// ----------------------------------------------------------------------

type ContentProps = {
  title: string;
  event_id: string;
  event_date: string;
};

export function Content({ title, event_id, event_date }: ContentProps) {
  const isDesktop = useResponsive("up", "md");
  const { orgId } = useParams();
  const { setStoreEvent } = useOrgStore();

  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
      }}
    >
      <Box display="flex">
        <Typography
          gutterBottom
          variant="caption"
          component="div"
          width="50%"
          sx={{
            color: "text.disabled",
          }}
        >
          {event_date}
        </Typography>
        <Box display="flex" justifyContent="flex-end" width="50%">
          <Link
            marginRight="10px"
            component={RouterLink}
            to={`/dashboard/orgs/${orgId}/events/${event_id}/tickets`}
            state={{ eventId: event_id, eventName: title }}
            onClick={() => {
              setStoreEvent({ id: event_id, name: title });
            }}
          >
            <ConfirmationNumberIcon />
          </Link>
          <Link
            component={RouterLink}
            to={`/dashboard/orgs/${orgId}/events/${event_id}/ticket-holders`}
            state={{ eventId: event_id, eventName: title }}
            onClick={() => {
              setStoreEvent({ id: event_id, name: title });
            }}
          >
            <PeopleOutlineIcon />
          </Link>
        </Box>
      </Box>

      <Link
        color="inherit"
        component={RouterLink}
        to={`/dashboard/orgs/${orgId}/events/${event_id}/overview`}
        // to={`/dashboard/orgs/${event_id}/events`}
      >
        <TextMaxLine variant={isDesktop ? "h5" : "subtitle2"} line={2} persistent>
          {title}
        </TextMaxLine>
      </Link>
    </CardContent>
  );
}
