import { CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";
import { OrgTicketHolderQueryParams } from "src/@types/organisation";
import { useOrgTicketHolderssQuery } from "src/hooks/api/org";

import {
  OrgTicketHoldersTable,
  OrgTicketHoldersToolbar,
} from "src/sections/@dashboard/OrgTicketHolders";

// HOC
import withOrgDefaultRedirection from "src/layouts/withOrgDefaultRedirection";
import { useOrgStore } from "src/global-stores/useOrgStore";

// eslint-disable-next-line import/no-extraneous-dependencies
import { useQueryParams, StringParam, NumberParam, withDefault } from "use-query-params";
import { limitNumberWithinRange } from "src/utils/formatNumber";
import useLocalStorage from "src/hooks/useLocalStorage";

type Props = {
  default_org_id?: string;
  showHeader?: boolean;
  allowEventFilter?: boolean;
};

const queryParamDefaultVals = {
  page: withDefault(NumberParam, 0),
  "user-state": withDefault(StringParam, "all"),
  search: withDefault(StringParam, ""),
};
const LS_KEY = "rowsPerPage:ticket-holders";
const OrgTicketHolders: FC<Props> = ({ default_org_id, showHeader, allowEventFilter = true }) => {
  const { orgId, eventId } = useParams();
  const { event: storedEvent } = useOrgStore();

  const [limit, setRowsPerPage] = useLocalStorage(LS_KEY, 5);
  const [query, setQuery] = useQueryParams(queryParamDefaultVals);
  const queryParamState = {
    limit,
    "user-state": (query["user-state"] || "all") as OrgTicketHolderQueryParams["user-state"],
    search: query.search || "",
    page: query.page || 0,
  };

  const { data, isLoading, isFetching, isPreviousData } = useOrgTicketHolderssQuery(
    `${orgId === "default" ? default_org_id : orgId}`,
    {
      ...queryParamState,
      ...(allowEventFilter && (storedEvent || eventId) ? { event: eventId } : {}),
    }
  );
  // console.log(isFetching, isLoading, data);
  if (isLoading && !isPreviousData) {
    return <CircularProgress />;
  }

  const handleFilterQueryParams = (obj: Partial<OrgTicketHolderQueryParams>) => {
    if ("limit" in obj) {
      setRowsPerPage(limitNumberWithinRange(obj.limit));
    } else {
      setQuery(obj as any, "pushIn");
    }
  };
  const onResetFilter = () => {
    setQuery({
      search: undefined,
    });
  };

  if (data?.success === false) {
    return (
      <Typography variant="h5" fontStyle="italic" textAlign="center">
        You don&apos;t currently have access to any organisations.
      </Typography>
    );
  }

  const tableData = data?.ticket_holders?.rows || [];

  return (
    <OrgTicketHoldersTable
      responseData={data!}
      currentTab={queryParamState["user-state"]}
      handleFilterQueryParams={handleFilterQueryParams}
      queryParamState={queryParamState}
      isLoading={isFetching}
      showCreateHeader={showHeader}
      showSelectedEventChip={allowEventFilter}
      toolbarComp={
        <OrgTicketHoldersToolbar
          handleFilterQueryParams={handleFilterQueryParams}
          queryParamState={queryParamState}
          onResetFilter={onResetFilter}
          isFiltered={!!queryParamState?.search}
          rows={tableData}
        />
      }
    />
  );
};

export default withOrgDefaultRedirection(OrgTicketHolders, "ticket-holders");
