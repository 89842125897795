import { useState } from "react";
// @mui
import {
  Link,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Checkbox,
  TableCellProps,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import { useNavigate, useParams } from "react-router";

import Label from "src/components/label/Label";
import { TBaseRow } from "src/@types/api";
// utils
import { fDate } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
// import ConfirmDialog from "../../../components/confirm-dialog";

// -----------------------------------------------------------------
type Props = {
  row: TBaseRow;
  selected: boolean;
  headLabel: {
    id: string;
    label: string;
    align: string;
  }[];
  onSelectRow: VoidFunction;
  onViewRow?: VoidFunction;
};

export default function OrgTicketHoldersTableRow({
  row,
  headLabel,
  selected,
  onSelectRow,
  onViewRow,
}: Props) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    handleOpenConfirm();
    handleCloseConfirm();
  };

  const handleClosePopover = () => {
    setOpenPopover(openConfirm ? null : null);
  };

  return (
    <>
      <TableRow hover>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        {headLabel.map((head, i) => {
          const key = head.id as keyof TBaseRow;

          const val = `${key}`?.includes("date") ? fDate(row[key]) : row[key] ?? "-";

          return (
            <TableCell
              onClick={() => {
                if (i === 0) {
                  onViewRow?.();
                }
              }}
              key={key}
              align={head.align as TableCellProps["align"]}
              sx={{ cursor: i === 0 ? "pointer" : undefined }}
            >
              {val}
            </TableCell>
          );
        })}

        <TableCell align="right">
          <IconButton color={openPopover ? "inherit" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem disabled>
          <Iconify icon="eva:eye-fill" />
          View
        </MenuItem>
      </MenuPopover>
    </>
  );
}
