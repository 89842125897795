// @mui
import { Card, Stack, Typography, Divider } from "@mui/material";
// utils
import { fNumber } from "../../../../../utils/formatNumber";
// @types
import { IUserProfileEventTicket } from "../../../../../@types/user";

// ----------------------------------------------------------------------

export default function ProfileEventTicketInfo({ events, tickets }: IUserProfileEventTicket) {
  return (
    <Card sx={{ py: 3 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(events)}</Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Events
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(tickets)}</Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Tickets
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
