import { useTickHolderComms } from "src/hooks/api/org";
import { useParams } from "react-router";

import { OrgBaseTable, OrgBaseToolbar } from "src/sections/@dashboard/OrgBaseTable";
import useLocalStorage from "src/hooks/useLocalStorage";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { TBaseQueryParam } from "src/@types/api";
import { limitNumberWithinRange } from "src/utils/formatNumber";
import ProfileHolderWrapper from "./ProfileHolderWrapper";

const queryParamDefaultVals = {
  page: withDefault(NumberParam, 0),
  search: withDefault(StringParam, ""),
};
const LS_KEY = "rowsPerPage:comms";
const THComms = () => {
  const { orgId, ticketHolderId } = useParams();

  const [limit, setRowsPerPage] = useLocalStorage(LS_KEY, 5);
  const [query, setQuery] = useQueryParams(queryParamDefaultVals);
  const queryParamState = {
    limit,
    search: query.search || "",
    page: query.page || 0,
  };

  const { data, isLoading } = useTickHolderComms(orgId || "", ticketHolderId || "", {
    ...queryParamState,
  });

  const handleFilterQueryParams = (obj: Partial<TBaseQueryParam>) => {
    if ("limit" in obj) {
      setRowsPerPage(limitNumberWithinRange(obj.limit));
    } else {
      setQuery(obj as any, "pushIn");
    }
  };
  const onResetFilter = () => {
    setQuery({
      search: undefined,
    });
  };

  return (
    <ProfileHolderWrapper>
      <OrgBaseTable
        tabs={[{ value: "all", label: "All" }]}
        currentTab="all"
        tableData={data?.comms}
        responseData={data!}
        queryParamState={queryParamState}
        heading="Comms"
        isLoading={isLoading}
        showCreateHeader={false}
        handleFilterQueryParams={handleFilterQueryParams}
        toolbarComp={
          <OrgBaseToolbar
            handleFilterQueryParams={handleFilterQueryParams}
            queryParamState={queryParamState}
            onResetFilter={onResetFilter}
            isFiltered={!!queryParamState?.search}
          />
        }
      />
    </ProfileHolderWrapper>
  );
};

export default THComms;
