import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
// routes
import { PATH_AUTH } from "src/routes/paths";
// components
import FormProvider, { RHFTextField, RHFCodes } from "src/components/hook-form";

import axios from "src/utils/axios";
import { TAccountConfirm } from "src/@types/api";

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
  email: string;
};

const ConfirmAccountSchema = Yup.object().shape({
  code1: Yup.string().required("Code is required"),
  code2: Yup.string().required("Code is required"),
  code3: Yup.string().required("Code is required"),
  code4: Yup.string().required("Code is required"),
  code5: Yup.string().required("Code is required"),
  code6: Yup.string().required("Code is required"),
  email: Yup.string().required("Email is required").email("Email must be a valid email address"),
});
export default function ConfirmAccount() {
  const navigate = useNavigate();
  const location = useLocation();

  const emailRecovery = location.state?.email ? location.state?.email : "";

  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
    email: emailRecovery || "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(ConfirmAccountSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const reqBody = {
        verify_code: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
        email: data.email,
      };
      const res = await axios
        .post<any, { data: TAccountConfirm }>("/user/account/confirm", reqBody)
        .then((rr) => rr.data);
      if (res.success) {
        toast.success(res.display_message, { duration: 5000 });
        navigate(PATH_AUTH.login, { state: { email: data.email } });
      } else {
        toast.error(res.display_message, { duration: 5000 });
      }
      console.log(res.display_message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="Email"
          disabled={!!emailRecovery}
          InputLabelProps={{ shrink: true }}
        />

        <RHFCodes keyName="code" inputs={["code1", "code2", "code3", "code4", "code5", "code6"]} />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            Code is required
          </FormHelperText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Confirm Account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
