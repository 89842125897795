import { Button, Hidden, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import Iconify from "./iconify/Iconify";

const BackButton = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Tooltip title="Back">
      <Button sx={{ color: "text.secondary" }} onClick={handleBack}>
        <Iconify icon="eva:arrow-ios-back-fill" />
        <Hidden smDown>
          <Typography variant="button">Back</Typography>
        </Hidden>
      </Button>
    </Tooltip>
  );
};

export default BackButton;
