import { Box, Chip, CircularProgress, Typography, Link } from "@mui/material";
import { useUserTicketWallQuery } from "src/hooks/api/user";
import { Profile } from "src/sections/@dashboard/user/profile";
import { StringParam, useQueryParam } from "use-query-params";

import { useOrgStore } from "src/global-stores/useOrgStore";
import { Link as RouterLink } from "react-router-dom";

import Iconify from "src/components/iconify";
import OrgTickets from "./OrgTickets";

const TicketWall = () => {
  const { data, isLoading } = useUserTicketWallQuery();
  const [eventQuery] = useQueryParam("event", StringParam);
  const { event } = useOrgStore();

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box>
      {!eventQuery ? (
        <Profile
          info={{
            events: data.event_count,
            tickets: data.ticket_count,
            id: "123",
            socialLinks: {},
            quote: data.about_text,
          }}
          events={data?.events || []}
        />
      ) : (
        <Box>
          <Box display="flex" gap={0.5}>
            <Typography variant="h5" mb={3} fontWeight={900}>
              {event?.name}
              {isLoading ? (
                <CircularProgress size={20} sx={{ ml: 2 }} />
              ) : (
                <Chip sx={{ ml: 1 }} label={event?.event_date || ""} />
              )}
            </Typography>
          </Box>
          <OrgTickets ticketsBy="USER" showHeader={false} />
        </Box>
      )}
    </Box>
  );
};

export default TicketWall;
