// @mui
import { Tab, Tabs, Container, Typography, Chip, CircularProgress } from "@mui/material";

import { Outlet, useLocation, useNavigate, useParams } from "react-router";

// routes
// import { PATH_DASHBOARD } from "src/routes/paths";
// auth
// import { useAuthContext } from "src/auth/useAuthContext";

// components
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import { useEventOverviewQuery } from "src/hooks/api/events";
// sections

const TABS = [
  {
    value: "overview",
    label: "Overview",
    icon: <Iconify icon="ic:round-account-box" />,
  },
  {
    value: "tickets",
    label: "Tickets",
    icon: <Iconify icon="eva:people-fill" />,
  },
  {
    value: "ticket-holders",
    label: "Ticket Holders",
    icon: <Iconify icon="ic:round-perm-media" />,
  },
];
// ----------------------------------------------------------------------

export default function EventsPageWrapper() {
  const { orgId, eventId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();

  const { data, isLoading } = useEventOverviewQuery(orgId || "", eventId || "");

  const getLastPath = pathname.split("/").pop();
  return (
    <>
      {/* <Helmet>
        <title> Ticket Wall | TX.IS</title>
      </Helmet> */}

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h3" mb={3} fontWeight={900}>
          {data?.event_name}
          {isLoading ? (
            <CircularProgress size={20} sx={{ ml: 2 }} />
          ) : (
            <Chip sx={{ ml: 1 }} label={data?.event_date || ""} />
          )}
        </Typography>
        <Tabs
          value={getLastPath}
          onChange={(_, newValue) => navigate(newValue)}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            mb: 3,
            // position: "absolute",
            bgcolor: "background.paper",
            borderRadius: "10px",
            "& .MuiTabs-flexContainer": {
              px: { md: 2 },
              // justifyContent: {
              //   sm: "center",
              //   md: "flex-end",
              // },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Container>
      <Outlet />
    </>
  );
}
