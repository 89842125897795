import axios from "src/utils/axios";
import { useQuery } from "@tanstack/react-query";
import { PATH_DASHBOARD } from "src/routes/paths";
import { UserFriendsResponse, UserOrgsResponse } from "src/@types/profile";

export const useUserTicketWallQuery = () => {
  const query = useQuery({
    queryKey: [PATH_DASHBOARD.user.ticketwall],
    queryFn: () => {
      return axios.get("/user/ticket-wall");
    },
  });
  return {
    ...query,
    data: query.data?.data,
  };
};

export const useUserFriendsQuery = () => {
  const query = useQuery<UserFriendsResponse>({
    queryKey: [PATH_DASHBOARD.user.friends],
    queryFn: () => {
      return axios.get("/user/friends").then((res) => res.data);
    },
  });
  return query;
};
export const useOrgsQuery = () => {
  const query = useQuery<UserOrgsResponse>({
    queryKey: [PATH_DASHBOARD.user.orgs],
    queryFn: () => {
      return axios.get("/user/organisations").then((res) => res.data);
    },
  });
  return query;
};

export const userNotifications = () => {
  return axios.get("/user/notifications").then(({ data }) => data);
};

export const userPasswordReset = (email: string) => {
  return axios.post("/user/password/reset", { email }).then(({ data }) => data);
};

export const userPasswordChange = (reqBody: any) => {
  return axios.post("/user/password/change", reqBody).then(({ data }) => data);
};
export const updateUserPasswordAction = (reqBody: any) => {
  return axios.post("/user/password/update", reqBody).then(({ data }) => data);
};
export const updateUserAction = (reqBody: any) => {
  return axios.post("/user", reqBody).then(({ data }) => data);
};
