import axios from "src/utils/axios";
import { useQuery } from "@tanstack/react-query";
import { PATH_DASHBOARD } from "src/routes/paths";

import { EventOverviewResponse } from "src/@types/event";

export const useEventOverviewQuery = (orgId: string, eventId: string) => {
  const query = useQuery<EventOverviewResponse>({
    queryKey: [`${PATH_DASHBOARD.orgs.events}/overview`, orgId, eventId],
    queryFn: () => {
      if (!orgId) return Promise.resolve({ success: false });
      return axios.get(`/organisations/${orgId}/events/${eventId}`).then((res) => res.data);
    },
  });
  return query;
};
