import { useEffect, useRef, useMemo, useState } from "react";
import { debounce } from "lodash";
// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";

// types
import { OrgTicketsQueryParams } from "src/@types/organisation";
import { TBaseColumn } from "src/@types/api";
import ExportButtons from "src/components/export";

// components
import Iconify from "../../../components/iconify";
import { Block } from "./ticket-filters-result";
// ----------------------------------------------------------------------

// const INPUT_WIDTH = 160;

type Props = {
  columns: TBaseColumn[];
  rows?: any[];
  onResetFilter: VoidFunction;

  queryParamState: OrgTicketsQueryParams;
  handleFilterQueryParams: (obj: Partial<OrgTicketsQueryParams>) => void;
  // onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterService: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterStartDate: (value: Date | null) => void;
  // onFilterEndDate: (value: Date | null) => void;
};

export default function OrgTicketsTableToolbar({
  columns,
  rows,
  // isFiltered,
  queryParamState,
  onResetFilter,
  handleFilterQueryParams,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState<keyof OrgTicketsQueryParams>("block"); // Default option

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    handleFilterQueryParams({
      [name]: e.target.value || "",
    });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 900);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleChange2 = (e: SelectChangeEvent<string>) => {
    const name = e.target.value as keyof OrgTicketsQueryParams;
    if (inputRef.current) {
      inputRef.current.value = `${queryParamState[name] || ""}`;
      console.log("inputRef.current.value->", inputRef.current.value);
    }
    setSelectedOption(name);
  };

  const isFiltered = Object.entries(queryParamState).some(
    ([key, value]) => columns?.some(({ column_id }) => column_id === key) && value !== ""
  );
  console.log("isFiltered->", queryParamState, Object.values(queryParamState));
  return (
    <Box>
      <Stack
        // spacing={2}
        alignItems="center"
        direction={{
          xs: "column",
          md: "row",
        }}
        gap={2}
        sx={{ px: 2.5, py: 2 }}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Property</InputLabel>

          <Select
            value={selectedOption}
            onChange={handleChange2}
            sx={{ width: "10rem" }}
            label="Property"
            placeholder="Property"
          >
            {columns.map((colObj, index) => {
              return (
                <MenuItem key={index} value={colObj.column_id} defaultChecked={index === 0}>
                  {colObj.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          name={selectedOption}
          inputRef={inputRef}
          // value={queryParamState[selectedOption]}
          defaultValue={queryParamState[selectedOption]}
          onChange={debouncedResults}
          // onChange={handleChange}
          placeholder={`Search `}
          label={`Search `}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
        />

        <ExportButtons className="ml-auto" dataList={rows || []} prefixName="tickets" />
      </Stack>
      {isFiltered && (
        <Stack
          sx={{ px: 2.5, pb: 1 }}
          flexGrow={1}
          spacing={1}
          direction="row"
          flexWrap="wrap"
          alignItems="center"
        >
          <Block label="Searches:" sx={{}}>
            {columns.map((colObj, index) => {
              if (queryParamState[colObj.column_id as keyof OrgTicketsQueryParams]) {
                return (
                  <Chip
                    key={index}
                    size="small"
                    label={`${colObj.title}: ${
                      queryParamState[colObj.column_id as keyof OrgTicketsQueryParams]
                    }`}
                    onDelete={() => {
                      if (inputRef.current!.name === colObj.column_id) {
                        inputRef.current!.value = "";
                      }
                      handleFilterQueryParams({
                        [colObj.column_id]: undefined,
                      });
                    }}
                  />
                );
              }
              return null;
            })}
          </Block>
          <Button
            color="error"
            onClick={() => {
              inputRef.current!.value = "";
              onResetFilter();
            }}
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
          >
            Clear
          </Button>
        </Stack>
      )}
    </Box>
  );
}
