import { FC, useState } from "react";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { Stack, Card, InputAdornment, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { IUserAccountChangePassword } from "src/@types/user";
import { updateUserPasswordAction } from "src/hooks/api/user";
import { toast } from "react-hot-toast";

type FormValuesProps = IUserAccountChangePassword;

const changePWSchema = Yup.object().shape({
  password_old: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters")
    .test(
      "no-match",
      "New password must be different than old password",
      (value, { parent }) => value !== parent.password_old
    ),
  confirmNewPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
});
const defaultValues = {
  password_old: "",
  password: "",
  confirmNewPassword: "",
};
const ChangePassword: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({
    resolver: yupResolver(changePWSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await updateUserPasswordAction(data);
      if (res?.success) {
        toast.success(res?.display_message);
      } else {
        toast.error(res.display_message, { duration: 8000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Stack spacing={3} alignItems="flex-end" sx={{ p: 3 }}>
          <RHFTextField
            name="password_old"
            type={showPassword ? "text" : "password"}
            label="Old Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="password"
            type={showPassword ? "text" : "password"}
            label="New Password"
            helperText={
              <Stack component="span" direction="row" alignItems="center">
                <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Password must be
                minimum 6+
              </Stack>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="confirmNewPassword"
            type={showPassword ? "text" : "password"}
            label="Confirm New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
};

export default ChangePassword;
