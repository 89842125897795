import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
// routes
import { userPasswordReset } from "src/hooks/api/user";
import { PATH_AUTH } from "../../routes/paths";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
};

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email must be a valid email address"),
});
export default function AuthResetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const prefilledEmail = location.state?.email ? location.state?.email : "";

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: prefilledEmail ?? "" },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await userPasswordReset(data.email);
      if (res.success) {
        toast.success(res.display_message);
        navigate(PATH_AUTH.secure.password.update, { state: { email: data.email } });
      } else {
        toast.error(res.display_message);
      }
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(isSubmitting);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 3 }}
      >
        Send Request
      </LoadingButton>
    </FormProvider>
  );
}
