import { Helmet } from "react-helmet-async";
// @mui
import { Tab, Card, Tabs, Container } from "@mui/material";

import { useLocation, useNavigate } from "react-router";

// import { useAuthContext } from "src/auth/useAuthContext";

// components
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
// sections
import { ProfileCover } from "src/sections/@dashboard/user/profile";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  name: string;
  tagline: string;
  photo_url: string;
  cover: string;
};
export default function TicketProfileWrapper({
  children,
  name,
  tagline = "",
  photo_url,
  cover,
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const currentTab = pathname.split("/").slice(-1)[0];
  return (
    <>
      <Helmet>
        <title> Ticket Holder</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: "relative",
          }}
        >
          <ProfileCover photo_url={photo_url} name={name} role={tagline} cover={cover} />
        </Card>

        {children}
      </Container>
    </>
  );
}
