import { CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";
import { OrgTicketsQueryParams } from "src/@types/organisation";
import { useOrgTicketsQuery } from "src/hooks/api/org";

import {
  OrganisationTicketsTable,
  OrgTicketsTableToolbar,
} from "src/sections/@dashboard/OrgTickets";

// HOC
import withOrgDefaultRedirection from "src/layouts/withOrgDefaultRedirection";
import { useOrgStore } from "src/global-stores/useOrgStore";
import useLocalStorage from "src/hooks/useLocalStorage";

// eslint-disable-next-line import/no-extraneous-dependencies
import { useQueryParams, StringParam, NumberParam, withDefault } from "use-query-params";
import { limitNumberWithinRange } from "src/utils/formatNumber";

type Props = {
  default_org_id?: string;
  forTicketHolder?: boolean;
  showHeader?: boolean;
  allowEventFilter?: boolean;
  ticketsBy?: "USER" | "ORG";
};

const queryParamDefaultVals = {
  page: withDefault(NumberParam, 0),
  "ticket-state": withDefault(StringParam, "all"),
  search: withDefault(StringParam, ""),
  "seat-section": withDefault(StringParam, ""),
  "seat-subsection": withDefault(StringParam, ""),
  event: withDefault(StringParam, ""),
  event_name: withDefault(StringParam, ""),
  stand: withDefault(StringParam, ""),
  row: withDefault(StringParam, ""),
  seat: withDefault(StringParam, ""),
  block: withDefault(StringParam, ""),
};
const LS_KEY = "rowsPerPage:tickets";
const OrgTickets: FC<Props> = ({
  default_org_id,
  ticketsBy = "ORG",
  forTicketHolder,
  showHeader,
  allowEventFilter = true,
}) => {
  const { orgId, ticketHolderId, eventId } = useParams();
  const { event: storedEvent } = useOrgStore();
  const [limit, setRowsPerPage] = useLocalStorage(LS_KEY, 5);
  const [query, setQuery] = useQueryParams(queryParamDefaultVals);
  const queryParamState = {
    ...query,
    limit,
    "ticket-state": (query["ticket-state"] || "all") as OrgTicketsQueryParams["ticket-state"],
    search: query.search || "",
    "seat-section": query["seat-section"] || "",
    "seat-subsection": query["seat-subsection"] || "",
    page: query.page || 0,
  };

  const { data, isLoading, isFetching, isPreviousData, refetch } = useOrgTicketsQuery(
    `${orgId === "default" ? default_org_id : orgId}`,
    {
      ...queryParamState,
      ...(allowEventFilter && !forTicketHolder && (storedEvent?.id || eventId)
        ? { event: eventId || storedEvent?.id }
        : {}),
      ...(ticketHolderId ? { user: ticketHolderId } : {}),
    },
    ticketsBy === "USER"
  );

  if (isLoading && !isPreviousData) {
    return <CircularProgress />;
  }

  if (data?.success === false) {
    return (
      <Typography variant="h5" fontStyle="italic" textAlign="center">
        You don&apos;t currently have access to any organisations.
      </Typography>
    );
  }

  const handleFilterQueryParams = (obj: Partial<OrgTicketsQueryParams>) => {
    if ("limit" in obj) {
      setRowsPerPage(limitNumberWithinRange(obj.limit));
    } else {
      setQuery(obj as any, "pushIn");
    }
  };
  const onResetFilter = () => {
    const qs: any = {};
    filtersCols.forEach(({ column_id }) => {
      qs[column_id] = undefined;
    });
    setQuery(qs);
  };
  const filtersCols =
    data?.tickets?.columns?.filter(
      ({ searchable, property_type }) => property_type === "STRING" && searchable
    ) || [];

  const tableData = data?.tickets?.rows || [];

  return (
    <OrganisationTicketsTable
      key={queryParamState?.page}
      responseData={data!}
      currentTab={queryParamState["ticket-state"]}
      handleFilterQueryParams={handleFilterQueryParams}
      queryParamState={queryParamState}
      isLoading={isFetching}
      refetch={() => refetch()}
      showCreateHeader={showHeader}
      showSelectedEventChip={allowEventFilter}
      toolbarComp={
        <OrgTicketsTableToolbar
          handleFilterQueryParams={handleFilterQueryParams}
          queryParamState={queryParamState}
          onResetFilter={onResetFilter}
          columns={filtersCols}
          rows={tableData}
        />
      }
    />
  );
};

export default withOrgDefaultRedirection(OrgTickets, "tickets");
