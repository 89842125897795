import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";

import { Chip, Container, Grid, Typography } from "@mui/material";
import { OrgOverviewResponse } from "src/@types/profile";

import AppWidgetSummary from "./AppWidgetSummary";
import EventCard from "./EventCard";

import { SkeletonPostItem } from "../../../components/skeleton";
// ----------------------------------------------------------------------

type Props = {
  org?: OrgOverviewResponse;
};
export default function GeneralAppPage({ org }: Props) {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Organisation Overview | TX.IS</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h3" mb={3}>
          {org?.organisation_name}
          <Chip sx={{ ml: 1 }} label="Org" />
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Events"
              percent={2.6}
              total={org?.events_total as number}
              chart={{
                colors: [theme.palette.primary.main],
                series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20],
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Tickets"
              percent={0.2}
              total={org?.tickets_total as number}
              chart={{
                colors: [theme.palette.info.main],
                series: [20, 41, 63, 33, 28, 35, 50, 46, 11, 26],
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Ticket Holders"
              percent={-0.1}
              total={org?.ticket_holder_total as number}
              chart={{
                colors: [theme.palette.warning.main],
                series: [8, 9, 31, 8, 16, 37, 8, 33, 46, 31],
              }}
            />
          </Grid>
        </Grid>

        <Typography variant="h4" mt={10} mb={4}>
          Events
        </Typography>

        <Grid container spacing={3}>
          {org?.events?.map((event, index) =>
            event ? (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <EventCard index={index} {...event} />
              </Grid>
            ) : (
              <SkeletonPostItem key={index} />
            )
          )}
        </Grid>
      </Container>
    </>
  );
}
