import React from "react";

import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { AppWidgetSummary } from "src/sections/@dashboard/app";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSettingsContext } from "src/components/settings";
import { useEventOverviewQuery } from "src/hooks/api/events";
import AnalyticsCurrentVisits from "src/sections/@dashboard/app/AnalyticsCurrentVisits";
import AnalyticsWebsiteVisits from "src/sections/@dashboard/app/AnalyticsWebsiteVisits";

const EventOverview = () => {
  const theme = useTheme();

  const { orgId, eventId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();

  const { data, isLoading } = useEventOverviewQuery(orgId || "", eventId || "");
  return (
    <Container maxWidth={themeStretch ? false : "lg"}>
      <Grid container spacing={3}>
        {data?.header_charts.map((chart) => (
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title={chart.title}
              percent={Number(chart.percentage)}
              total={chart.value}
              chart={{
                colors: [theme.palette.primary.main],
                series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20],
              }}
            />
          </Grid>
        ))}
        {/*<Grid item xs={12} md={6} lg={8}>*/}
        {/*  <AnalyticsWebsiteVisits*/}
        {/*    title={data?.distribution_chart?.title}*/}
        {/*    chart={{*/}
        {/*      labels: [*/}
        {/*        "01/01/2003",*/}
        {/*        "02/01/2003",*/}
        {/*        "03/01/2003",*/}
        {/*        "04/01/2003",*/}
        {/*        "05/01/2003",*/}
        {/*        "06/01/2003",*/}
        {/*        "07/01/2003",*/}
        {/*        "08/01/2003",*/}
        {/*        "09/01/2003",*/}
        {/*        "10/01/2003",*/}
        {/*        "11/01/2003",*/}
        {/*      ],*/}
        {/*      series: [*/}
        {/*        {*/}
        {/*          name: "Team A",*/}
        {/*          type: "column",*/}
        {/*          fill: "solid",*/}
        {/*          data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],*/}
        {/*        },*/}
        {/*        {*/}
        {/*          name: "Team B",*/}
        {/*          type: "area",*/}
        {/*          fill: "gradient",*/}
        {/*          data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],*/}
        {/*        },*/}
        {/*        {*/}
        {/*          name: "Team C",*/}
        {/*          type: "line",*/}
        {/*          fill: "solid",*/}
        {/*          data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],*/}
        {/*        },*/}
        {/*      ],*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12} md={6} lg={4}>*/}
        {/*  <AnalyticsCurrentVisits*/}
        {/*    title={data?.ticket_state_chart?.title}*/}
        {/*    chart={{*/}
        {/*      series: [*/}
        {/*        { label: "America", value: 4344 },*/}
        {/*        { label: "Asia", value: 5435 },*/}
        {/*        { label: "Europe", value: 1443 },*/}
        {/*        { label: "Africa", value: 4443 },*/}
        {/*      ],*/}
        {/*      colors: [*/}
        {/*        theme.palette.primary.main,*/}
        {/*        theme.palette.info.main,*/}
        {/*        theme.palette.error.main,*/}
        {/*        theme.palette.warning.main,*/}
        {/*      ],*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>
    </Container>
  );
};

export default EventOverview;
