import * as Yup from "yup";
import { EditUserForm } from "src/@types/user";
import { FC } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack, Alert, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAuthContext } from "src/auth/useAuthContext";
import { CustomAvatar } from "src/components/custom-avatar";

import FormProvider, { RHFSwitch, RHFTextField, RHFSelect } from "src/components/hook-form";
import { updateUserAction } from "src/hooks/api/user";
import { toast } from "react-hot-toast";
// ----------------------------------------------------------------------

const profileState = [
  {
    name: "Public",
    value: "PUBLIC",
    msg: "You will appear in friend searches. People you agree to be friends with can see your events.",
  },
  {
    name: "Hide Events",
    value: "PUBLIC_HIDE_EVENTS",
    msg: "You will appear in friend searches. Your friends will not see which events you are attending.",
  },
  {
    name: "Private",
    value: "PRIVATE",
    msg: "You will not appear in friend searches and friends will not see which events you are attending.",
  },
];
const contactPermissions = [
  {
    name: "Send occasional offers",
    value: "true",
  },
  {
    name: "Don't send offers",
    value: "false",
  },
];

const UpdateUserSchema = Yup.object().shape({
  forename: Yup.string().required("Name is required"),
  surname: Yup.string().required("Name is required"),
  tagline: Yup.string().required("Tagline is required"),
  about_text: Yup.string().required("About Text is required"),
  photo_url: Yup.string(),
  publish_ticket_wall: Yup.bool(),
  profile_state: Yup.string().required("Profile Type is required"),
  contact_permission_txis: Yup.bool().required("Contact Permission is required"),
});

const GeneralSettings: FC = () => {
  const { user, refreshUser } = useAuthContext();

  const defaultValues = {
    forename: user?.forename || "",
    surname: user?.surname || "",
    photo_url: user?.photo_url || "",
    tagline: user?.tagline || "",
    profile_state: user?.profile_state || "",
    contact_permission_txis: user?.contact_permission_txis || false,
    about_text: user?.about_text || "",
    publish_ticket_wall: user?.publish_ticket_wall || false,
  };
  const methods = useForm<EditUserForm>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  const watchFields = watch(["publish_ticket_wall", "profile_state", "contact_permission_txis"]);
  const onSubmit = async (data: any) => {
    try {
      const res = await updateUserAction(data);
      if (res?.success) {
        refreshUser();
        toast.success(res?.display_message);
      } else {
        toast.error(res.display_message);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              py: 10,
              px: 3,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomAvatar
              src={user?.photo_url}
              alt={user?.forename}
              name="photoURL"
              sx={{ width: 120, height: 120 }}
            />
            <RHFSwitch
              name="publish_ticket_wall"
              labelPlacement="start"
              label="Publish Ticket Wall"
              sx={{ mt: 5 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFTextField name="forename" label="Forename" />
              <RHFTextField name="surname" label="Surname" />
            </Box>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="tagline" rows={4} label="Tagline" />
            </Stack>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="about_text" multiline rows={4} label="About" />
            </Stack>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ mt: 3 }}
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFSelect
                native
                name="profile_state"
                label="Profile Type"
                placeholder="Profile Type"
              >
                {profileState.map((state) => (
                  <option key={state.name} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect native name="contact_permission_txis" label="Contact Permission">
                {contactPermissions.map((state) => (
                  <option key={state.name} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </RHFSelect>
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Alert color="success" icon={false} sx={{ width: "100%", textAlign: "center", p: 4 }}>
                <Stack spacing={3} alignItems="center" width="100%">
                  <Typography variant="h4">Your Privacy Settings</Typography>
                  <Typography variant="body1">
                    {/* publish_ticket_wall */}
                    {watchFields[0] === false
                      ? "Your Ticket Wall of past events is not available on the Internet."
                      : "Your Ticket Wall of past events is available on the Internet."}
                  </Typography>
                  <Typography variant="body1">
                    {/* profile_state */}
                    {profileState.find((o) => o.value === watchFields[1])?.msg}
                  </Typography>
                  <Typography variant="body1">
                    {/* contact_permission_txis */}
                    {`${watchFields[2]}` === "true"
                      ? "We may send you occasional emails about events and products."
                      : "We will not send you any offers."}
                  </Typography>
                </Stack>
              </Alert>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default GeneralSettings;
