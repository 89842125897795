import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";

import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

// export default function ProductFiltersResult({
//   filters,
//   onFilters,
//   //
//   canReset,
//   onResetFilters,
//   //
//   results,
//   ...other
// }) {
//   return (
//     <Stack spacing={1.5} {...other}>
//       <Box sx={{ typography: "body2" }}>
//         <strong>{results}</strong>
//         <Box component="span" sx={{ color: "text.secondary", ml: 0.25 }}>
//           results found
//         </Box>
//       </Box>

//       <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
//         {!!filters.rating && (
//           <Block label="Rating:">
//             <Chip size="small" label={filters.rating} onDelete={onResetFilters} />
//           </Block>
//         )}

//         {canReset && (
//           <Button
//             color="error"
//             onClick={onResetFilters}
//             startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
//           >
//             Clear
//           </Button>
//         )}
//       </Stack>
//     </Stack>
//   );
// }

// ProductFiltersResult.propTypes = {
//   canReset: PropTypes.bool,
//   filters: PropTypes.object,
//   onFilters: PropTypes.func,
//   results: PropTypes.number,
//   onResetFilters: PropTypes.func,
// };

// ----------------------------------------------------------------------

export function Block({ label, children, sx, ...other }: any) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: "hidden",
        borderStyle: "dashed",
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: "subtitle2" }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

// Block.propTypes = {
//   children: PropTypes.node,
//   label: PropTypes.string,
//   sx: PropTypes.objectOf(PropTypes.object),
// };
