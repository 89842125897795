import { useTickHolderProfile } from "src/hooks/api/org";
import { useParams } from "react-router";
import { CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";

import { Block } from "src/components/Block";
import ProfileHolderWrapper from "./ProfileHolderWrapper";

const Profile = () => {
  const { orgId, ticketHolderId } = useParams();
  const { data, isLoading, isPreviousData } = useTickHolderProfile(
    orgId || "",
    ticketHolderId || "",
    {
      "add-activity": true,
    }
  );

  if (isLoading && !isPreviousData) {
    return <CircularProgress />;
  }

  if (data?.success === false) {
    return (
      <Typography variant="h4" fontStyle="italic">
        {data?.display_message}
      </Typography>
    );
  }

  // const lastItem = data?.activity[data?.activity?.length - 1].id;

  // const reduceTimeLine = TIMELINES.slice(TIMELINES.length - 3);
  const activityLen = data?.activity?.length || 0;
  return (
    <ProfileHolderWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <TextField label="Full Name" disabled value={`${data?.forename} ${data?.surname}`} />
            <TextField label="Email" disabled value={`${data?.email}`} />
            <TextField label="Account State" disabled value={`${data?.user_state}`} />
            <TextField label="Last Online" disabled value={`${data?.last_online}`} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Block title="Activity">
            <Timeline position="alternate">
              {data?.activity?.length
                ? data?.activity.map((item, i) => (
                    <TimelineItem key={i}>
                      <TimelineOppositeContent>
                        <Typography sx={{ color: "text.secondary" }}>{item.date}</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: item.colour }} />
                        {activityLen - 1 === i ? null : <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography> {item.name}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))
                : null}
            </Timeline>
          </Block>
        </Grid>
      </Grid>
    </ProfileHolderWrapper>
  );
};

export default Profile;
