import { useMemo, useState } from "react";
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Alert,
  Stack,
  Typography,
} from "@mui/material";
import { OrgTicketsResponse } from "src/@types/profile";

import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "src/components/hook-form/FormProvider";
import { RHFTextField } from "src/components/hook-form";
import axios from "src/utils/axios";
import { useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
import Iconify from "src/components/iconify/Iconify";

const content = {
  RECLAIM: {
    title: "Reclaim Tickets",
    description: "To reclaim the following tickets, click the Reclaim button below.",
    confimrationMsg: "Please confirm that you would like to reclaim",
    btnText: "Reclaim",
    btnColor: "error",
  },
  DISTRIBUTE: {
    title: "Distribute Tickets",
    description: "To distribute the following tickets, click the Distribute button below.",
    confimrationMsg: "Please confirm that you would like to distribute",
    btnText: "Distribute",
    btnColor: "success",
  },
};
// ----------------------------------------------------------------------
type Props = {
  open: boolean;
  openHandler: (val: boolean) => void;
  selectedTicketIds: string[];
  tickets: OrgTicketsResponse["tickets"]["rows"];
  onSuccess: VoidFunction;
};

type FormValuesProps = {
  forename: string;
  surname: string;
  email: string;
  afterSubmit?: string;
};

const distributeSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email must be a valid email address"),
  surname: Yup.string().required("Surname is required"),
  forename: Yup.string().required("Forename is required"),
});

function ActionForm({ open, selectedTicketIds, tickets, openHandler, onSuccess }: Props) {
  const { orgId } = useParams();
  const [confirmationOpen, setConfimationOpen] = useState(false);

  const confirmationHandle = (val: boolean) => () => {
    setConfimationOpen(val);
  };
  const selectedTickets = useMemo(() => {
    return selectedTicketIds?.map((ticketId) => {
      const ticketData = tickets?.find((ticket) => ticket.row_id === ticketId);
      return ticketData;
    });
  }, [tickets, selectedTicketIds]);

  const ticket_action = (
    selectedTickets?.length ? selectedTickets?.[0]?.ticket_action || "DISTRIBUTE" : "RECLAIM"
  ) as "RECLAIM" | "DISTRIBUTE";

  // ----------------------------------------------------------------------

  const methods = useForm<FormValuesProps>({
    resolver: ticket_action === "DISTRIBUTE" ? yupResolver(distributeSchema) : undefined,
    defaultValues: {
      forename: "",
      surname: "",
      email: "",
    },
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (formdata: FormValuesProps) => {
    try {
      console.log("submitting distribute");
      const { data } = await axios.post(`/organisations/${orgId}/tickets/transfer/email`, {
        ticket_ids: selectedTicketIds,
        ...formdata,
      });
      if (data?.success) {
        toast.success("Tickets distributed successfully");
        onSuccess();
        setConfimationOpen(false);
        openHandler(false);
      } else {
        setError("afterSubmit", {
          message: data?.display_message || "Something went wrong",
        });
        toast.error(data?.display_message || "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: error.message || error,
      });
    }
  };
  const onReclaim = async () => {
    try {
      console.log("submitting reclaim");
      const { data } = await axios.delete(`/organisations/${orgId}/tickets/transfer/cancel`, {
        data: { ticket_ids: selectedTicketIds },
      });
      if (data?.success) {
        toast.success("Tickets reclaimed successfully");
        onSuccess();
        setConfimationOpen(false);
        openHandler(false);
      } else {
        setError("afterSubmit", {
          message: data?.display_message || "Something went wrong",
        });
        toast.error(data?.display_message || "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,

        message: error.message || error,
      });
    }
  };

  return (
    <>
      <DialogTitle>{content[ticket_action].title}</DialogTitle>
      <DialogContent>
        <FormProvider
          methods={methods}
          onSubmit={ticket_action === "RECLAIM" ? handleSubmit(onReclaim) : handleSubmit(onSubmit)}
        >
          {!confirmationOpen ? (
            <DialogContentText>{content[ticket_action].description}</DialogContentText>
          ) : (
            <DialogContentText>{content[ticket_action].confimrationMsg}</DialogContentText>
          )}
          <Box p={1} my={2}>
            <span>Tickets:</span>
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginTop: 8,
              }}
            >
              {selectedTickets?.map((ticket) => (
                <li
                  key={ticket?.row_id}
                  style={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Iconify icon="ph:arrow-right-bold" /> {ticket?.claim_info}
                </li>
              ))}
            </ul>
          </Box>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          {ticket_action === "DISTRIBUTE" ? (
            <Box>
              {confirmationOpen ? (
                <Stack spacing={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {getValues("forename")} {getValues("surname")}
                  </Typography>
                  <Typography variant="body1">{getValues("email")}</Typography>
                </Stack>
              ) : (
                <Stack spacing={2}>
                  <RHFTextField name="email" label="Email address" />
                  <RHFTextField name="forename" type="text" label="Forename" />
                  <RHFTextField name="surname" type="text" label="Surname" />
                </Stack>
              )}
            </Box>
          ) : null}
          <DialogActions sx={{ px: "0px !important" }}>
            <Button
              onClick={() => (confirmationOpen ? confirmationHandle(false)() : openHandler(false))}
              color="inherit"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            {!confirmationOpen ? (
              <Button
                type="button"
                onClick={handleSubmit(async () => {
                  setConfimationOpen(true);
                  return true;
                })}
                variant="contained"
                color={content[ticket_action].btnColor as "success" | "error"}
                disabled={isSubmitting}
              >
                {content[ticket_action].btnText}
              </Button>
            ) : (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color={content[ticket_action].btnColor as "success" | "error"}
              >
                Confirm {ticket_action === "RECLAIM" ? "Reclaim" : "Distribute"}
              </LoadingButton>
            )}
          </DialogActions>
        </FormProvider>
      </DialogContent>
    </>
  );
}

export default function TicketActionDialog({
  open,
  selectedTicketIds,
  tickets,
  openHandler,
  onSuccess,
}: Props) {
  return (
    <Dialog open={open} onClose={() => false} maxWidth="sm" fullWidth disableEscapeKeyDown>
      {selectedTicketIds?.length ? (
        <ActionForm
          open={open}
          openHandler={openHandler}
          selectedTicketIds={selectedTicketIds}
          tickets={tickets}
          onSuccess={onSuccess}
        />
      ) : null}
    </Dialog>
  );
}
