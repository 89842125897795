import axios from "src/utils/axios";
import { useQuery } from "@tanstack/react-query";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
  OrgEventsResponse,
  OrgOverviewResponse,
  OrgTicketHoldersResponse,
  OrgTicketsResponse,
  OrgTicketResponse,
  TicketHolderProfileResponse,
  TicketHolderTicketsResponse,
} from "src/@types/profile";
import {
  OrgEventsQueryParams,
  OrgTicketHolderQueryParams,
  OrgTicketsQueryParams,
  OrgTHProfileQueryParams,
} from "src/@types/organisation";
import { TBaseQueryParam, TTicketHolderCommsResponse } from "src/@types/api";
import { useDebounce } from "use-debounce";

import { RQ_KEYS } from "./keys";

export const useOrgOverviewQuery = (orgId: string) => {
  const query = useQuery<OrgOverviewResponse>({
    queryKey: [PATH_DASHBOARD.orgs.overview, orgId],
    queryFn: () => {
      if (!orgId) return Promise.resolve({ success: false });
      return axios.get(`/organisations/${orgId}/overview`).then((res) => res.data);
    },
  });
  return query;
};

export const useOrgEventsQuery = (orgId: string, params: OrgEventsQueryParams) => {
  const paramValues = Object.values(params || {});
  const query = useQuery<OrgEventsResponse>({
    keepPreviousData: true,
    queryKey: [PATH_DASHBOARD.orgs.events, orgId, ...paramValues],
    queryFn: () => {
      const url = new URL(`${axios.defaults.baseURL}/organisations/${orgId}/events`);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined || value !== null || value !== "") {
          url.searchParams.set(key, `${value}`);
        }
        if (typeof value === "undefined") {
          url.searchParams.delete(key);
        }
      });
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");
      return axios.get(path).then((res) => res.data);
    },
  });
  return query;
};

export const useOrgTicketsQuery = (
  orgId: string,
  params: OrgTicketsQueryParams,
  byUser?: boolean
) => {
  // const paramValues = useDebounce(Object.values(params || {}).join("-"), 1500);
  const paramValues = Object.values(params || {}).join("-");

  // console.log(" paramValues->", paramValues);
  const query = useQuery<OrgTicketsResponse>({
    queryFn: ({ signal }: any) => {
      const url = new URL(
        byUser
          ? `${axios.defaults.baseURL}/user/tickets`
          : `${axios.defaults.baseURL}/organisations/${orgId}/tickets`
      );
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined || value !== null || value !== "") {
          url.searchParams.set(key, `${value}`);
        }
        if (typeof value === "undefined") {
          url.searchParams.delete(key);
        }
      });
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");
      return axios.get(path, { signal }).then((res) => res.data);
    },
    keepPreviousData: true,
    queryKey: [PATH_DASHBOARD.orgs.tickets, orgId, paramValues],
  });
  return query;
};

export const useOrgTicketQuery = (orgId: string, ticketId: string) => {
  const query = useQuery<OrgTicketResponse>({
    keepPreviousData: true,
    queryKey: [PATH_DASHBOARD.orgs.tickets, orgId, ticketId],
    queryFn: () => {
      const url = new URL(`${axios.defaults.baseURL}/organisations/${orgId}/ticket/${ticketId}`);
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");
      return axios.get(path).then((res) => res.data);
    },
  });
  return query;
};
export const useOrgTicketHolderssQuery = (orgId: string, params: OrgTicketHolderQueryParams) => {
  const paramValues = Object.values(params || {});
  const query = useQuery<OrgTicketHoldersResponse>({
    keepPreviousData: true,
    queryKey: [PATH_DASHBOARD.orgs.ticketholder, orgId, ...paramValues],
    queryFn: () => {
      const url = new URL(`${axios.defaults.baseURL}/organisations/${orgId}/ticket-holders`);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined || value !== null || value !== "") {
          url.searchParams.set(key, `${value}`);
        }
        if (typeof value === "undefined") {
          url.searchParams.delete(key);
        }
      });
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");
      return axios.get(path).then((res) => res.data);
    },
    staleTime: 20000,
  });
  return query;
};
export const useTickHolderProfile = (
  orgId: string,
  ticketHolderId: string,
  params?: OrgTHProfileQueryParams
) => {
  const query = useQuery<TicketHolderProfileResponse>({
    queryKey: [
      RQ_KEYS.ticket_holder_profile,
      orgId,
      ticketHolderId,
      ...Object.values(params || {}),
    ],
    queryFn: () => {
      const url = new URL(
        `${axios.defaults.baseURL}/organisations/${orgId}/ticket-holders/${ticketHolderId}`
      );
      Object.entries(params || {}).forEach(([key, value]) => {
        if (value !== undefined || value !== null || value !== "") {
          url.searchParams.set(key, `${value}`);
        }
        if (typeof value === "undefined") {
          url.searchParams.delete(key);
        }
      });
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");

      return axios.get(path).then((res) => res.data);
    },
  });
  return query;
};
export const useTickHolderTickets = (orgId: string, ticketHolderId: string) => {
  const query = useQuery<TicketHolderTicketsResponse>({
    queryKey: [RQ_KEYS.ticket_holder_tickets, orgId, ticketHolderId],
    queryFn: () => {
      return axios
        .get(`/organisations/${orgId}/ticket-holders/${ticketHolderId}/tickets`)
        .then((res) => res.data);
    },
  });
  return query;
};
export const useTickHolderComms = (
  orgId: string,
  ticketHolderId: string,
  params?: TBaseQueryParam
) => {
  const paramValues = Object.values(params || {});
  const query = useQuery<TTicketHolderCommsResponse>({
    queryKey: [RQ_KEYS.ticket_holder_comms(orgId, ticketHolderId), ...paramValues],
    queryFn: () => {
      const url = new URL(
        `${axios.defaults.baseURL}/organisations/${orgId}/ticket-holders/${ticketHolderId}/comms`
      );
      Object.entries(params || {}).forEach(([key, value]) => {
        if (value !== undefined || value !== null || value !== "") {
          url.searchParams.set(key, `${value}`);
        }
        if (typeof value === "undefined") {
          url.searchParams.delete(key);
        }
      });
      const path = `${url.toString()}`.replace(axios.defaults.baseURL || "", "");
      return axios.get(path).then((res) => res.data);
    },
  });
  return query;
};
