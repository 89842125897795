// @mui
import { Stack, Typography, Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
// auth
// import { useAuthContext } from "../../auth/useAuthContext";
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";

// ----------------------------------------------------------------------

export default function Login() {
    return (
        <LoginLayout>
            <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
                <Typography variant="h4">Sign in to TX.IS</Typography>

                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2">New user?</Typography>

                    <Link component={RouterLink} variant="subtitle2" to="https://tx.is/#get_app">
                        Download the app
                    </Link>
                </Stack>
            </Stack>

            <AuthLoginForm />

            {/* <AuthWithSocial /> */}
        </LoginLayout>
    );
}
