import ProfileHolderWrapper from "./ProfileHolderWrapper";
import OrgTickets from "../OrgTickets";

const THTicket = () => {
  return (
    <ProfileHolderWrapper>
      <OrgTickets forTicketHolder />
    </ProfileHolderWrapper>
  );
};

export default THTicket;
