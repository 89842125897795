import { useState } from "react";
// @mui
import {
  Link,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Checkbox,
  TableCellProps,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import { matchPath, useLocation, useNavigate, useParams } from "react-router";

import { OrgTicketHoldersResponse } from "src/@types/profile";
import Label from "src/components/label/Label";
// utils
import { fDate } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
// import ConfirmDialog from "../../../components/confirm-dialog";

// ----------------------------------------------------------------------
type IRow = OrgTicketHoldersResponse["ticket_holders"]["rows"]["0"];
type Props = {
  row: IRow;
  selected: boolean;
  headLabel: {
    id: string;
    label: string;
    align: string;
  }[];
  onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
};

export default function OrgTicketHoldersTableRow({
  row,
  headLabel,
  selected,
  onSelectRow,
}: // onViewRow,
Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { row_id } = row;
  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    handleOpenConfirm();
    handleCloseConfirm();
  };

  const handleClosePopover = () => {
    setOpenPopover(openConfirm ? null : null);
  };
  const isOrgEventsRoute = matchPath("/dashboard/orgs/:orgId/events/*", pathname);
  // const ticketDetailPagePath = orgId && row_id ? `/dashboard/orgs/${orgId}/tickets/${row_id}` : "";
  // eslint-disable-next-line no-nested-ternary
  const actionPath = orgId
    ? isOrgEventsRoute
      ? `/dashboard/orgs/${orgId}/ticket-holders/${row_id}`
      : `${row_id}`
    : "";
  return (
    <>
      <TableRow hover>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        {headLabel.map((head) => {
          const key = head.id as keyof IRow;

          const val = key.includes("date") ? fDate(row[key]) : row[key] ?? "-";
          if (key === "state") {
            return (
              <TableCell
                onClick={() => {
                  navigate(`${actionPath}/tickets`, {
                    state: {
                      holderId: row_id,
                    },
                  });
                }}
                key={key}
                align="left"
              >
                <Label
                  variant="soft"
                  color={
                    (val?.toLowerCase() === "active" && "success") ||
                    (val?.toLowerCase() === "invited" && "error") ||
                    "default"
                  }
                >
                  {val}
                </Label>
              </TableCell>
            );
          }
          return (
            <TableCell
              onClick={() => {
                navigate(`${actionPath}/tickets`, {
                  state: {
                    holderId: row_id,
                  },
                });
              }}
              key={key}
              align={head.align as TableCellProps["align"]}
            >
              {val}
            </TableCell>
          );
        })}

        <TableCell align="right">
          <IconButton color={openPopover ? "inherit" : "default"} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem
          onClick={() => {
            navigate(`${actionPath}/profile`, {
              state: {
                holderId: row_id,
              },
            });
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`${actionPath}/tickets`, {
              state: {
                holderId: row_id,
              },
            });
          }}
        >
          <Iconify icon="eva:eye-fill" />
          {`View Holder's tickets`}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
