import { Helmet } from "react-helmet-async";
// @mui
import { Typography } from "@mui/material";
// sections
import ConfirmAccount from "src/sections/user/confirm-account";
// assets
import { PasswordIcon } from "src/assets/icons";

// ----------------------------------------------------------------------

export default function RegisterConfirm() {
  return (
    <>
      <Helmet>
        <title> Register Confirm | TX.IS</title>
      </Helmet>

      <PasswordIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Confirm your email
      </Typography>

      <Typography sx={{ color: "text.secondary", mb: 5 }}>
        Please enter the email address and the code we have emailed you, then click Confirm Account.
      </Typography>

      <ConfirmAccount />
    </>
  );
}
