import { FC } from "react";
import { useParams } from "react-router";
import { CircularProgress, Typography } from "@mui/material";
import { useOrgOverviewQuery } from "src/hooks/api/org";
import withOrgDefaultRedirection from "src/layouts/withOrgDefaultRedirection";
import GeneralAppPage from "src/sections/@dashboard/app/GeneralAppPage";

type Props = {
  default_org_id: string;
};
const OrgOverview: FC<Props> = ({ default_org_id }) => {
  const { orgId } = useParams();
  const { data, isLoading } = useOrgOverviewQuery(
    `${orgId === "default" ? default_org_id : orgId}`
  );

  if (isLoading) {
    return <CircularProgress />;
  }
  if (data?.success === false) {
    return (
      <Typography variant="h5" fontStyle="italic" textAlign="center">
        You don&apos;t currently have access to any organisations.
      </Typography>
    );
  }

  return <GeneralAppPage org={data} />;
};

export default withOrgDefaultRedirection(OrgOverview, "overview");
