import { useState, useRef } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Checkbox,
  InputBase,
  Typography,
  CardHeader,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";

import { StringParam, useQueryParam } from "use-query-params";
import { useOrgStore } from "src/global-stores/useOrgStore";
// @types
import { IUserProfileEvent } from "../../../../../@types/user";
// auth
import { useAuthContext } from "../../../../../auth/useAuthContext";
// utils
import { fDate } from "../../../../../utils/formatTime";
import { fShortenNumber } from "../../../../../utils/formatNumber";
// components
import Image from "../../../../../components/image";
import Iconify from "../../../../../components/iconify";
import { CustomAvatar, CustomAvatarGroup } from "../../../../../components/custom-avatar";

// ----------------------------------------------------------------------

interface Props {
  event: IUserProfileEvent;
}

export default function ProfilePostCard({ event }: Props) {
  const [eventQuery, setEventQuery] = useQueryParam("event", StringParam);
  const { setStoreEvent } = useOrgStore();

  return (
    <Card
      onClick={() => {
        setStoreEvent({ id: event.event_id, name: event.event_name, event_date: event.event_date });
        setEventQuery(event.event_id);
      }}
      sx={{ cursor: "pointer" }}
    >
      <CardHeader
        disableTypography
        avatar={
          <CustomAvatar
            src={event?.organisation_image_url}
            alt={event?.event_name}
            name={event?.event_name}
          />
        }
        title={
          <Link color="inherit" variant="subtitle2">
            {event?.event_name}
          </Link>
        }
        subheader={
          <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>
            {event?.event_date}
          </Typography>
        }
        action={
          <IconButton>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        }
      />

      <Box sx={{ p: 1 }}>
        <Image alt="post media" src={event.event_image_url} ratio="16/9" sx={{ borderRadius: 1 }} />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          p: (theme) => theme.spacing(2, 3, 3, 3),
        }}
      >
        <CustomAvatarGroup>
          {event?.friends_attending?.map((person) => (
            <CustomAvatar
              key={person.friend_id}
              alt={`${person.forename} ${person.surname}`}
              src={person.photo_url}
            />
          ))}
        </CustomAvatarGroup>
      </Stack>
    </Card>
  );
}
