import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Link,
  Stack,
  Avatar,
  MenuItem,
  IconButton,
  Typography,
  InputAdornment,
  Button,
  Popover,
} from "@mui/material";
// @types
import { IUserProfileOrg } from "src/@types/profile";
import { useOrgStore } from "src/global-stores/useOrgStore";

// components
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import { CustomTextField } from "../../../../components/custom-input";
import SearchNotFound from "../../../../components/search-not-found";

// ----------------------------------------------------------------------

type Props = {
  orgs: IUserProfileOrg[];
  searchQuery: string;
  onSearchQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ProfileOrganisations({ orgs, searchQuery, onSearchQuery }: Props) {
  const dataFiltered = applyFilter(orgs, searchQuery);

  const isNotFound = !dataFiltered.length && !!orgs;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log(orgs);
  return (
    <>
      <Stack
        spacing={3}
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        sx={{ my: 5 }}
      >
        <Typography variant="h4">Organisations</Typography>

        <Stack direction="row" spacing={2}>
          <CustomTextField
            width={220}
            size="small"
            value={searchQuery}
            onChange={onSearchQuery}
            placeholder="Search organisations..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={handleClick}
            variant="contained"
            startIcon={<Iconify icon="material-symbols:add" />}
          >
            Organization
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>Coming soon!</Typography>
          </Popover>
        </Stack>
      </Stack>

      {isNotFound && <SearchNotFound query={searchQuery} sx={{ mt: 10 }} />}
      {/* eslint-disable-next-line no-nested-ternary */}

      {orgs.length ? (
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
        >
          {dataFiltered.map((org) => (
            <OrganisationCard key={org.organisation_id} data={org} />
          ))}
        </Box>
      ) : (
        <Typography textAlign="center">
          You don&apos;t currently have access to any organisations.
        </Typography>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type OrganisationCardCardProps = {
  data: IUserProfileOrg;
};

function OrganisationCard({ data }: OrganisationCardCardProps) {
  const { organisation_id, organisation_name, organisation_line_2, logo_url } = data;
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { setOrdId, setDefault } = useOrgStore();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDelete = () => {
    handleClosePopover();
    console.log("DELETE", organisation_name);
  };

  const handleEdit = () => {
    handleClosePopover();
    console.log("EDIT", organisation_name);
  };

  return (
    <>
      <Card
        sx={{
          py: 5,
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar alt={organisation_name} src={logo_url} sx={{ width: 64, height: 64, mb: 3 }} />

        <Link
          component={RouterLink}
          to={`/dashboard/orgs/${organisation_id}/overview`}
          variant="subtitle1"
          color="text.primary"
          onClick={() => {
            setDefault();
            setOrdId(organisation_id);
          }}
        >
          {organisation_name}
        </Link>

        <Typography variant="body2" sx={{ color: "text.secondary", mb: 1, mt: 0.5 }}>
          {organisation_line_2}
        </Typography>

        <IconButton
          color={openPopover ? "inherit" : "default"}
          onClick={handleOpenPopover}
          sx={{ top: 8, right: 8, position: "absolute" }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Card>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter(inputData: IUserProfileOrg[], query: string) {
  if (query) {
    return inputData.filter(
      (data) => data.organisation_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}
