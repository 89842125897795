import { Helmet } from "react-helmet-async";
// @mui
import { Tab, Card, Tabs, Container, CircularProgress, Typography } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router";

// import { useAuthContext } from "src/auth/useAuthContext";

// components
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
// sections
import { ProfileCover } from "src/sections/@dashboard/user/profile";
import { useTickHolderProfile } from "src/hooks/api/org";

const TABS = [
  {
    value: "profile",
    label: "Profile",
    icon: <Iconify icon="ic:round-account-box" />,
  },
  {
    value: "tickets",
    label: "Tickets",
    icon: <Iconify icon="eva:people-fill" />,
  },
  {
    value: "comms",
    label: "Comms",
    icon: <Iconify icon="eva:people-fill" />,
  },
];
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  // name: string;
  // tagline: string;
  // photo_url: string;
  // cover: string;
};
export default function ProfileHolderWrapper({
  children,
}: // name,
// tagline = "",
// photo_url,
// cover,
Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { orgId, ticketHolderId } = useParams();
  const { data, isLoading, isPreviousData } = useTickHolderProfile(
    orgId || "",
    ticketHolderId || ""
  );

  if (isLoading && !isPreviousData) {
    return <CircularProgress />;
  }

  if (data?.success === false) {
    return (
      <Typography variant="h4" fontStyle="italic">
        {data?.display_message}
      </Typography>
    );
  }

  const currentTab = pathname.split("/").slice(-1)[0];

  const name = `${data?.forename} ${data?.surname}`;
  const cover = data?.background_image_url || "";
  const tagline = data?.tagline || "";
  const photo_url = data?.photo_url || "";
  return (
    <>
      <Helmet>
        <title> Ticket Holder</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: "relative",
          }}
        >
          <ProfileCover photo_url={photo_url} name={name} role={tagline} cover={cover} />

          <Tabs
            value={currentTab}
            onChange={(_, newValue) => {
              if (currentTab === newValue) {
                navigate("");
              } else {
                const newpath = pathname.replace(`/${currentTab}`, `/${newValue}`);
                console.log(newpath);
                navigate(newpath);
              }
            }}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: "absolute",
              bgcolor: "background.paper",
              "& .MuiTabs-flexContainer": {
                pr: { md: 3 },
                justifyContent: {
                  sm: "center",
                  md: "flex-end",
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {children}
      </Container>
    </>
  );
}
