import React, { ChangeEvent, useState } from "react";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";

// @mui
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { OrgTicketsQueryParams } from "src/@types/organisation";

// routes
import { OrgTicketsResponse } from "src/@types/profile";
import { useOrgStore } from "src/global-stores/useOrgStore";
// components
import ExportButtons from "src/components/export";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  // getComparator,
  // emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import OrgTicketsTableRow from "./OrgTicketsTableRow";
import TicketActionDialog from "./ActionDialog";

// ----------------------------------------------------------------------

type Props = {
  toolbarComp: React.ReactElement;
  responseData: OrgTicketsResponse;
  currentTab: string;
  queryParamState: OrgTicketsQueryParams;
  isLoading: boolean;
  showCreateHeader?: boolean;
  showSelectedEventChip?: boolean;
  handleFilterQueryParams: (obj: Partial<OrgTicketsQueryParams>) => void;
  refetch: VoidFunction;
};

const TABS = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "distribute", label: "Distribute" },
  { value: "undelivered", label: "Undelivered" },
  { value: "delivered", label: "Delivered" },
  { value: "cancelled", label: "Cancelled" },
  { value: "staging", label: "Staging" },
] as const;
// ----------------------------------------------------------------------
const handleClick = () => {
  console.log("colorName");
};
export default function OrganisationEventsTable({
  responseData,
  currentTab,
  queryParamState,
  toolbarComp,
  isLoading,
  showCreateHeader = true,
  showSelectedEventChip = false,
  handleFilterQueryParams,
  refetch,
}: Props) {
  const { event: storedEvent, setStoreEvent } = useOrgStore();
  const { pathname } = useLocation();
  const tableData = responseData?.tickets?.rows || [];
  const isNotFound =
    !tableData.length || (tableData.length && tableData.length === 1 && !tableData[0]?.row_id);
  const TABLE_HEAD = [
    ...(responseData?.tickets?.columns?.map((clm) => ({
      id: clm?.column_id,
      label: clm?.title,
      align: clm?.alignment === "CENTRE" ? "center" : clm?.alignment?.toLowerCase(),
    })) || []),

    ...(isNotFound ? [] : [{ id: "action", label: "", align: "right" }]),
  ];

  const { themeStretch } = useSettingsContext();
  const [isActionOpen, setActionDialog] = useState(false);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    // setPage,
    selected,
    // setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: Number(queryParamState.page),
    defaultRowsPerPage: Number(queryParamState.limit),
  });

  const denseHeight = dense ? 56 : 76;
  const firstSelectedTicketState = selected.length
    ? tableData.find((row) => row.row_id === selected[0])?.ticket_action
    : null;

  const isTHTicketsPathActive = matchPath(
    "/dashboard/orgs/:orgId/ticket-holders/:ticketHolderId/tickets",
    pathname
  );

  return (
    <Container maxWidth={themeStretch ? false : "lg"}>
      {!isTHTicketsPathActive && showCreateHeader ? (
        <Stack direction="row" justifyContent="space-between" mb={4}>
          <Typography variant="h3">
            <span>All Tickets</span>
            {showSelectedEventChip && storedEvent && (
              <Chip
                sx={{ ml: 1 }}
                label={`Event: ${storedEvent?.name}`}
                onDelete={() => setStoreEvent(undefined)}
              />
            )}
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Button
              onClick={handleClick}
              component={RouterLink}
              to=""
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Ticket
            </Button>
          </Stack>
        </Stack>
      ) : null}

      <Card>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => {
            handleFilterQueryParams({
              page: 0,
              "ticket-state": newValue,
            });
          }}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        <Divider />

        {toolbarComp}
        <TicketActionDialog
          open={isActionOpen}
          openHandler={setActionDialog}
          selectedTicketIds={selected}
          tickets={tableData}
          onSuccess={() => {
            onSelectAllRows(false, []);
            refetch();
          }}
        />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              onSelectAllRows(
                checked,
                tableData.map((row) => row.row_id)
              )
            }
            action={
              firstSelectedTicketState !== "NONE" ? (
                <Stack direction="row">
                  <Tooltip title="Distribute/Reclaim">
                    <IconButton color="primary" onClick={() => setActionDialog(true)}>
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ) : null
            }
          />

          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                showCheckbox
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.row_id)
                  )
                }
              />

              <TableBody>
                {!isLoading &&
                  !isNotFound &&
                  tableData.map((row, key) => (
                    <OrgTicketsTableRow
                      headLabel={TABLE_HEAD.slice(0, -1)}
                      key={row.row_id}
                      row={row}
                      selected={selected.includes(row.row_id)}
                      onSelectRow={() => onSelectRow(row.row_id)}
                      disableSelection={
                        firstSelectedTicketState
                          ? firstSelectedTicketState !== row.ticket_action
                          : false
                      }
                      // onViewRow={() => handleViewRow(row.id)}
                    />
                  ))}
                {isLoading && (
                  <TableEmptyRows isLoading height={denseHeight} emptyRows={rowsPerPage} />
                )}
                <TableEmptyRows height={denseHeight} emptyRows={Number(isNotFound)} />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          key={responseData?.tickets?.total_records}
          count={responseData?.tickets?.total_records}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(evt, newpage) => {
            onChangePage(evt, newpage);
            handleFilterQueryParams({
              page: Number(newpage),
            });
          }}
          onRowsPerPageChange={(evt: ChangeEvent<HTMLInputElement>) => {
            onChangeRowsPerPage(evt);
            handleFilterQueryParams({
              limit: Number(evt.target.value),
              page: 0,
            });
          }}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </Container>
  );
}
