// routes
import { FIREBASE_API } from "src/config-global";
import { PATH_AUTH } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number, refreshToken?: string | null) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    if (refreshToken) {
      try {
        const response = await (
          await fetch(`https://securetoken.googleapis.com/v1/token?key=${FIREBASE_API.apiKey}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              refresh_token: refreshToken,
              grant_type: "refresh_token",
            }),
            redirect: "follow",
          })
        ).json();
        console.log("timout-> ", response);
        setSession(response.id_token, response.refresh_token);
      } catch (err) {
        localStorage.removeItem("accessToken");
        window.location.href = PATH_AUTH.login;
        delete axios.defaults.headers.common.Authorization;
      }
    } else {
      localStorage.removeItem("accessToken");
      window.location.href = PATH_AUTH.login;
      delete axios.defaults.headers.common.Authorization;
    }
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null, refreshToken?: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp, refreshToken);
  } else {
    localStorage.removeItem("accessToken");

    delete axios.defaults.headers.common.Authorization;
  }
};
