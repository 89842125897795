import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useUserFriendsQuery } from "src/hooks/api/user";
import { ProfileFriends } from "src/sections/@dashboard/user/profile";

const ProfileFriendsPage = () => {
  const { data, isLoading } = useUserFriendsQuery();
  const [searchFriend, setSearchFriend] = useState("");

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <ProfileFriends
      friends={data?.friends || []}
      searchFriends={searchFriend}
      onSearchFriends={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFriend(event.target.value);
      }}
    />
  );
};

export default ProfileFriendsPage;
