import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
// routes
import axios from "src/utils/axios";
import { PATH_AUTH } from "src/routes/paths";
// components
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { TBaseResponse } from "src/@types/api";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from "react-google-recaptcha";
import { FormHelperText } from "@mui/material";
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  recaptcha: string;
};

export default function AccountCloseForm() {
  const navigate = useNavigate();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email must be a valid email address"),
    recaptcha: Yup.string().required("Recaptcha is required"),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: "", recaptcha: "" },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await axios
        .post<any, { data: TBaseResponse }>("/user/account/close", data)
        .then((rr) => rr.data);
      if (res?.success) {
        toast.success(res?.display_message, { duration: 10_000 });
        navigate(PATH_AUTH.register.confirm, { state: { email: data.email } });
      } else {
        toast.error(res.display_message, { duration: 10_000 });
      }
      console.log(res);
    } catch (err) {
      console.log(err);
      toast.error(err.message, { duration: 10_000 });
    }
  };
  console.log(isSubmitting);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField name="email" label="Email address" />
      <ReCAPTCHA
        sitekey={
          (import.meta.env.REACT_APP_VITE_RECAPTCHA_SITE_KEY as string) ||
          "6Lc8tg0kAAAAAJct9nE1jiE-VoDzoxN97QyD_4QJ"
        }
        onChange={(value: any) => {
          setValue("recaptcha", value);
        }}
        style={{ marginTop: "16px" }}
      />
      {errors.recaptcha?.message && (
        <FormHelperText error sx={{ display: "flex", alignItems: "center", mr: "auto !important" }}>
          <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> {errors.recaptcha?.message}
        </FormHelperText>
      )}
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 2 }}
      >
        Send Request
      </LoadingButton>
    </FormProvider>
  );
}
