import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Link, Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// components
import Iconify from "../../../components/iconify";
// sections
import AccountCloseForm from "../../../sections/auth/AccountCloseForm";
// assets
import { PasswordIcon } from "../../../assets/icons";

// ----------------------------------------------------------------------

export default function AccountCloseReqPage() {
  return (
    <>
      <Helmet>
        <title> TX.IS | Close Account</title>
      </Helmet>

      <PasswordIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Close TX.IS Account
      </Typography>

      <Typography sx={{ color: "text.secondary", mb: 5 }}>
        To close your TX.IS account, please fill in the form below and click Send Request. We will
        then email you a link so you can confirm the account deletion.
      </Typography>
      <Typography sx={{ color: "text.secondary", mb: 5 }}>
          If you no longer have access to the email address, please contact us on hello@tx.is so we
          can confirm your account details.
      </Typography>

      <AccountCloseForm />

      <Link
        component={RouterLink}
        to={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mt: 3,
          mx: "auto",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
    </>
  );
}
