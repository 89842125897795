// @mui
import { Grid, Stack } from "@mui/material";
// @types
import { IUserProfile, IUserProfileEvent } from "src/@types/user";
//
import ProfileAbout from "./ProfileAbout";
import ProfileEventCard from "./ProfileEventCard";
import ProfileEventTicketInfo from "./ProfileEventTicketInfo";

// ----------------------------------------------------------------------

type Props = {
  info: IUserProfile;
  events: IUserProfileEvent[];
};

export default function Profile({ info, events }: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <ProfileEventTicketInfo events={info.events} tickets={info.tickets} />

          <ProfileAbout
            quote={info.quote}
            country={info.country}
            email={info.email}
            role={info.role}
            company={info.company}
            school={info.school}
          />

          {/* <ProfileSocialInfo socialLinks={info.socialLinks} /> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {/* <ProfilePostInput /> */}

          {events.map((event) => (
            <ProfileEventCard key={event.event_id} event={event} />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
