import * as Yup from "yup";
import { FC, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Alert,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FormProvider, { RHFTextField, RHFSelect, RHFCheckbox } from "src/components/hook-form";
import { toast } from "react-hot-toast";
import Iconify from "src/components/iconify/Iconify";
import axios from "src/utils/axios";
import { TAccountCreate } from "src/@types/api";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from "react-google-recaptcha";

// ----------------------------------------------------------------------

const profileState = [
  {
    name: "Public",
    value: "PUBLIC",
    msg: "You will appear in friend searches. People you agree to be friends with can see your events.",
  },
  {
    name: "Hide Events",
    value: "PUBLIC_HIDE_EVENTS",
    msg: "You will appear in friend searches. Your friends will not see which events you are attending.",
  },
  {
    name: "Private",
    value: "PRIVATE",
    msg: "You will not appear in friend searches and friends will not see which events you are attending.",
  },
];
const contactPermissions = [
  {
    name: "Send occasional offers",
    value: "true",
  },
  {
    name: "Don't send offers",
    value: "false",
  },
];

const UpdateUserSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  forename: Yup.string().required("Name is required"),
  surname: Yup.string().required("Name is required"),
  password: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters")
    .test(
      "no-match",
      "New password must be different than old password",
      (value, { parent }) => value !== parent.password_old
    ),
  confirmNewPassword: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),

  profile_state: Yup.string().required("Profile Type is required"),
  contact_permission_txis: Yup.bool().required("Contact Permission is required"),

  agree: Yup.bool().oneOf([true], "You must agree to the Terms of Use"),
  recaptcha: Yup.string().required("Re-captcha required"),
});

const defaultValues = {
  email: "",
  forename: "",
  surname: "",
  password: "",
  confirmNewPassword: "",
  profile_state: "PUBLIC",
  contact_permission_txis: false,
  recaptcha: undefined,
  agree: false,
};
const CreateAccountForm: FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<Yup.InferType<typeof UpdateUserSchema>>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const watchFields = watch(["profile_state", "contact_permission_txis"]);
  const onSubmit = async (data: any) => {
    try {
      console.log("data", data);
      const res = await axios
        .post<any, { data: TAccountCreate }>("/user/account/create", data)
        .then((rr) => rr.data);
      if (res?.success) {
        toast.success(res?.display_message, { duration: 5000 });
        navigate(PATH_AUTH.register.confirm, { state: { email: data.email } });
      } else {
        toast.error(res.display_message, { duration: 10_000 });
      }
    } catch (err) {
      toast.error(err.message, { duration: 10_000 });
    }
  };
  console.log("errors", errors.agree);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <RHFTextField name="email" label="Email" type="email" />

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
              sx={{ mt: 3 }}
            >
              <RHFTextField name="forename" label="Forename" />
              <RHFTextField name="surname" label="Surname" />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
              sx={{ mt: 3 }}
            >
              <RHFTextField
                name="password"
                type={showPassword ? "text" : "password"}
                label="New Password"
                helperText={
                  <Stack component="span" direction="row" alignItems="center">
                    <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Password must be
                    minimum 6+
                  </Stack>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <RHFTextField
                name="confirmNewPassword"
                type={showPassword ? "text" : "password"}
                label="Confirm New Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ mt: 3 }}
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFSelect
                native
                name="profile_state"
                label="Profile Type"
                placeholder="Profile Type"
              >
                {profileState.map((state) => (
                  <option key={state.name} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect native name="contact_permission_txis" label="Contact Permission">
                {contactPermissions.map((state) => (
                  <option key={state.name} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </RHFSelect>
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Alert
                color="success"
                icon={false}
                sx={{ width: "100%", textAlign: "center", p: 4, justifyContent: "center" }}
              >
                <Stack spacing={1} sx={{ width: "100%", alignItems: "center" }}>
                  <Typography variant="h4">Your Privacy Settings</Typography>
                  <Typography variant="body1">
                    {/* profile_state */}
                    {profileState.find((o) => o.value === watchFields[0])?.msg}
                  </Typography>
                  <Typography variant="body1">
                    {/* contact_permission_txis */}
                    {`${watchFields[1]}` === "true"
                      ? "We may send you occasional emails about events and products."
                      : "We will not send you any offers."}
                  </Typography>
                </Stack>
              </Alert>
              <Box sx={{ mr: "auto !important", display: "flex", alignItems: "center" }}>
                <RHFCheckbox
                  name="agree"
                  label={
                    <p>
                      <span>I agree to the TX.IS</span>
                      <Link
                        href="https://tx.is/terms/end-user"
                        target="_blank"
                        rel="noreferrer"
                        // style={{ marginRight: 8, marginLeft: -10 }}
                      >
                        {" "}
                        Terms and Conditions
                      </Link>
                      <span> and </span>
                      <Link
                        href="https://tx.is/terms/transparency-notice"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy.
                      </Link>
                    </p>
                  }
                  helperText={errors.agree?.message}
                />
              </Box>

              <ReCAPTCHA
                sitekey={
                  (import.meta.env.REACT_APP_VITE_RECAPTCHA_SITE_KEY as string) ||
                  "6Lc8tg0kAAAAAJct9nE1jiE-VoDzoxN97QyD_4QJ"
                }
                onChange={(value: any) => {
                  setValue("recaptcha", value);
                }}
                style={{ marginRight: "auto" }}
              />
              {errors.recaptcha?.message && (
                <FormHelperText
                  error
                  sx={{ display: "flex", alignItems: "center", mr: "auto !important" }}
                >
                  <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />{" "}
                  {errors.recaptcha?.message}
                </FormHelperText>
              )}

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                // disabled={!isValid}
              >
                Join TX.IS
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default CreateAccountForm;
