import { useEffect, useRef, useMemo } from "react";
import { debounce } from "lodash";
// @mui
import { Stack, InputAdornment, TextField, Button, InputLabel } from "@mui/material";

// types
import { TBaseQueryParam } from "src/@types/api";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

type Props = {
  isFiltered?: boolean;
  onResetFilter: VoidFunction;

  queryParamState: TBaseQueryParam;
  handleFilterQueryParams: (obj: Partial<TBaseQueryParam>) => void;
  // onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterService: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterStartDate: (value: Date | null) => void;
  // onFilterEndDate: (value: Date | null) => void;
};

export default function OrgBaseTableToolbar({
  isFiltered,
  queryParamState,
  onResetFilter,
  handleFilterQueryParams,
}: Props) {
  const searchRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterQueryParams({
      search: e.target.value || "",
    });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 900);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <InputLabel id="demo-multiple-name-label">Name s</InputLabel>
      <TextField
        inputRef={searchRef}
        // value={queryParamState.search}
        defaultValue={queryParamState.search}
        onChange={debouncedResults}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={() => {
            if (searchRef.current) {
              searchRef.current.value = "";
            }

            onResetFilter();
          }}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
      {/* <DatePicker label="Basic date picker" /> */}
    </Stack>
  );
}
