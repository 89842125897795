import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useOrgsQuery } from "src/hooks/api/user";
import { ProfileOrganisations } from "src/sections/@dashboard/user/profile";

const ProfileOrganisationsPage = () => {
  const { data, isLoading } = useOrgsQuery();
  const [searchQuery, setSearchQuery] = useState("");

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <ProfileOrganisations
      orgs={data?.organisations || []}
      searchQuery={searchQuery}
      onSearchQuery={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
      }}
    />
  );
};

export default ProfileOrganisationsPage;
