import { Helmet } from "react-helmet-async";
import React, { ChangeEvent } from "react";
import { Link as RouterLink } from "react-router-dom";

// @mui
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Typography,
  Box,
} from "@mui/material";
import { OrgEventsQueryParams } from "src/@types/organisation";

// routes
import { OrgEventsResponse } from "src/@types/profile";

// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  // getComparator,
  // emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import OrgEventTableRow from "./OrgEventTableRow";

// ----------------------------------------------------------------------

type Props = {
  responseData: OrgEventsResponse;
  currentTab: string;
  queryParamState: OrgEventsQueryParams;
  isLoading: boolean;
  handleFilterQueryParams: (obj: Partial<OrgEventsQueryParams>) => void;
  toolbarComp: React.ReactElement;
};

const TABS = [
  { value: "all", label: "All" },
  { value: "upcoming", label: "Upcoming" },
  { value: "past", label: "Past" },
] as const;
// ----------------------------------------------------------------------

export default function OrganisationEventsTable({
  responseData,
  currentTab,
  queryParamState,
  toolbarComp,
  isLoading,
  handleFilterQueryParams,
}: Props) {
  const tableData = responseData?.events?.rows || [];
  const isNotFound =
    !tableData.length || (tableData.length && tableData.length === 1 && !tableData[0]?.row_id);
  const TABLE_HEAD = [
    ...(responseData?.events.columns?.map((clm) => ({
      id: clm?.column_id,
      label: clm?.title,
      align: clm?.alignment === "CENTRE" ? "center" : clm?.alignment?.toLowerCase(),
    })) || []),

    ...(isNotFound
      ? []
      : [
          { id: "ticket-action", label: "", align: "right" },
          { id: "ticket-holder-action", label: "", align: "right" },
          { id: "action", label: "", align: "right" },
        ]),
  ];

  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    // setPage,
    selected,
    // setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: queryParamState.page,
    defaultRowsPerPage: queryParamState.limit,
  });

  const denseHeight = dense ? 56 : 76;
  return (
    <>
      <Helmet>
        <title>Organisation Events | TX.IS</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" mb={4}>
          <Typography variant="h3">Events</Typography>
          <Box>
            <Button
              component={RouterLink}
              to=""
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Ticket
            </Button>
          </Box>
        </Stack>

        <Card>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => {
              handleFilterQueryParams({
                page: 0,
                timeframe: newValue,
              });
            }}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <Divider />

          {/* Search / date pick-->>>> */}
          {toolbarComp}
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.row_id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={() => {}}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  showCheckbox={false}
                />

                <TableBody>
                  {!isLoading &&
                    !isNotFound &&
                    tableData.map((row, key) => (
                      <OrgEventTableRow
                        key={row.row_id}
                        row={row}
                        headLabel={TABLE_HEAD.slice(0, -2)}
                        selected={selected.includes(row.row_id)}
                        onSelectRow={() => onSelectRow(row.row_id)}
                        // onViewRow={() => handleViewRow(row.id)}
                      />
                    ))}
                  {isLoading && (
                    <TableEmptyRows isLoading height={denseHeight} emptyRows={rowsPerPage} />
                  )}
                  <TableEmptyRows height={denseHeight} emptyRows={Number(isNotFound)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={responseData?.events?.total_records}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(evt, newpage) => {
              onChangePage(evt, newpage);
              handleFilterQueryParams({
                page: Number(newpage),
              });
            }}
            onRowsPerPageChange={(evt: ChangeEvent<HTMLInputElement>) => {
              onChangeRowsPerPage(evt);
              handleFilterQueryParams({
                limit: Number(evt.target.value),
                page: 0,
              });
            }}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
