import OrgTicketHolders from "../OrgTicketHolders";

const EventTicketHolder = () => {
  return (
    <div>
      <OrgTicketHolders showHeader={false} />
    </div>
  );
};

export default EventTicketHolder;
