import { Helmet } from "react-helmet-async";
// @mui
import { Tab, Card, Tabs, Container } from "@mui/material";

import { Outlet, useLocation, useNavigate } from "react-router";

// routes
// import { PATH_DASHBOARD } from "../../routes/paths";
// auth
import { useAuthContext } from "../../auth/useAuthContext";

// components
import Iconify from "../../components/iconify";
import { useSettingsContext } from "../../components/settings";
// sections
import { ProfileCover } from "../../sections/@dashboard/user/profile";

const TABS = [
  {
    value: "/dashboard/profile/ticket-wall",
    label: "Ticket Wall",
    icon: <Iconify icon="ic:round-account-box" />,
  },
  {
    value: "/dashboard/profile/friends",
    label: "Friends",
    icon: <Iconify icon="eva:people-fill" />,
  },
  {
    value: "/dashboard/profile/orgs",
    label: "Organisations",
    icon: <Iconify icon="ic:round-perm-media" />,
  },
];
// ----------------------------------------------------------------------

export default function UserProfilePage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();

  const authdd = useAuthContext();
  const { user } = authdd;
  return (
    <>
      <Helmet>
        <title> Ticket Wall | TX.IS</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: "relative",
          }}
        >
          <ProfileCover
            name={`${user?.forename} ${user?.surname}`}
            role={user?.tagline}
            cover={user?.background_image_url}
            photo_url={user?.photo_url}
          />

          <Tabs
            value={pathname}
            // onChange={(_, newValue) => navigate(newValue)}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: "absolute",
              bgcolor: "background.paper",
              "& .MuiTabs-flexContainer": {
                pr: { md: 3 },
                justifyContent: {
                  sm: "center",
                  md: "flex-end",
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                icon={tab.icon}
                label={tab.label}
                onClick={() => {
                  navigate(tab.value);
                }}
              />
            ))}
          </Tabs>
        </Card>

        <Outlet />
      </Container>
    </>
  );
}
