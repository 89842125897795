import { Button, ButtonGroup, IconButton, MenuItem } from "@mui/material";
import ExcelJS from "exceljs";
import { CSVLink } from "react-csv";
import CustomPopover, { usePopover } from "../custom-popover";
import Iconify from "../iconify";

// {prefixName}-{timestamp}.extension
// e.g. tickets-2021-10-10-.csv

type Props = {
  dataList: any[];
  prefixName: string;
  className?: string;
};

export default function ExportButtons({ dataList, prefixName, className }: Props) {
  const popover = usePopover();
  const filename = `${prefixName}-${new Date().toISOString().split("T")[0]}`;
  const exportToExcel = async () => {
    return new Promise<void>((resolve, reject) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      // Add headers
      const headers = Object.keys(dataList[0] || {});
      worksheet.addRow(headers);

      // Add data from dataList
      // eslint-disable-next-line react/prop-types
      dataList.forEach((rowData: { [x: string]: any }) => {
        const rowValues = headers.map((header) => rowData[header]);
        worksheet.addRow(rowValues);
      });
      // console.log("dataList", dataList);

      // Generate Excel file
      const fileName = filename || "exported_data";
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${fileName}.xlsx`;
          link.click();
          resolve();
        })
        .catch((error) => {
          console.error("Error exporting to Excel:", error);
          reject(error);
        });
    });
  };

  return (
    <div className={className}>
      <Button onClick={popover.onOpen} color="inherit">
        <Iconify icon="solar:export-bold" />
        Export
      </Button>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <CSVLink data={dataList} filename={filename} style={{ textDecoration: "none" }}>
          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
            sx={{ gap: "0.5rem", textDecoration: "none", color: "black" }}
          >
            <Iconify icon="solar:printer-minimalistic-bold" />
            CSV
          </MenuItem>
        </CSVLink>

        <MenuItem
          onClick={() => {
            exportToExcel();
            popover.onClose();
          }}
          sx={{ gap: "0.5rem" }}
        >
          <Iconify icon="solar:export-bold" />
          Excel
        </MenuItem>
      </CustomPopover>
    </div>
  );
}
