import { useEffect, useRef, useMemo } from "react";
import { debounce } from "lodash";

// @mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack, InputAdornment, TextField, Button } from "@mui/material";

// types
import { OrgEventsQueryParams } from "src/@types/organisation";
// components
import { TBaseColumn } from "src/@types/api";
import ExportButtons from "src/components/export";

import Iconify from "../../../components/iconify";
// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

type Props = {
  columns?: TBaseColumn[];
  rows?: any[];

  isFiltered?: boolean;
  onResetFilter: VoidFunction;

  queryParamState: OrgEventsQueryParams;
  handleFilterQueryParams: (obj: Partial<OrgEventsQueryParams>) => void;
  // onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterService: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterStartDate: (value: Date | null) => void;
  // onFilterEndDate: (value: Date | null) => void;
};

export default function OrgEventsTableToolbar({
  columns,
  rows,
  isFiltered,
  queryParamState,
  onResetFilter,
  handleFilterQueryParams,
}: Props) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterQueryParams({ search: e.target.value || "" });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 900);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("OrgEventsTableToolbar: useEffect: debouncedResults");
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <Stack
      gap={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        inputRef={searchInputRef}
        // value={queryParamState.search}
        defaultValue={queryParamState.search}
        onChange={debouncedResults}
        placeholder="Name..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />
      <DatePicker
        label="From date"
        format="dd/MM/yyyy"
        value={new Date(queryParamState.from || "")}
        onChange={(value) => {
          handleFilterQueryParams({
            from: value?.toISOString() || undefined,
          });
        }}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      />
      <DatePicker
        label="To date"
        value={new Date(queryParamState.to || "")}
        onChange={(value) => {
          handleFilterQueryParams({
            to: value?.toISOString() || undefined,
          });
        }}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={() => {
            onResetFilter();
            if (searchInputRef.current && searchInputRef.current.value) {
              searchInputRef.current.value = "";
            }
          }}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
      {/* <DatePicker label="Basic date picker" /> */}
      <ExportButtons className="ml-auto" dataList={rows || []} prefixName="events" />
    </Stack>
  );
}
