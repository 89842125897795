import { Outlet } from "react-router-dom";
// @mui
import { Stack, Container } from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// config
import { HEADER } from "../../config-global";
//
import Header from "./Header";

// ----------------------------------------------------------------------
type Props = {
  maxWidth?: number;
};
export default function CompactLayout({ maxWidth }: Props) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const hasMaxWidth = maxWidth !== undefined ? maxWidth : null;
  return (
    <>
      <Header isOffset={isOffset} />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: "auto",
            // maxWidth: 400,
            minHeight: "100vh",
            textAlign: "center",
            justifyContent: "center",
            ...(hasMaxWidth && { maxWidth: hasMaxWidth }),
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
