import { Navigate, useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// auth
import AuthGuard from "src/auth/AuthGuard";
import GuestGuard from "src/auth/GuestGuard";
// layouts
import CompactLayout from "src/layouts/compact";
import DashboardLayout from "src/layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
//

// import RoleBasedGuard from "src/auth/RoleBasedGuard";
import UserProfilePage from "src/pages/dashboard/UserProfilePage";
import TicketWallPage from "src/pages/dashboard/TicketWall";
import ProfileFriendsPage from "src/pages/dashboard/ProfileFriends";
import ProfileOrgsPage from "src/pages/dashboard/ProfileOrganisations";
import EditUserPage from "src/pages/dashboard/user/EditUser";
import OrgOverview from "src/pages/dashboard/OrgOverview";
import OrgEvents from "src/pages/dashboard/OrgEvents";
import OrgTickets from "src/pages/dashboard/OrgTickets";

// ----- Ticket Holder  -----
import OrgTicketHolders from "src/pages/dashboard/OrgTicketHolders";
// import ProfileHolderWrapper from "src/pages/dashboard/ticket-holder/ProfileHolderWrapper";
import THPorfile from "src/pages/dashboard/ticket-holder/Profile";
import THTickets from "src/pages/dashboard/ticket-holder/Tickets";
import THComms from "src/pages/dashboard/ticket-holder/Comms";

// ----------------------------------------------------------------------
import ResetPasswordPage from "src/pages/secure/password/reset";
import CreateAccountPage from "src/pages/secure/register";
import AuthNewPasswordForm from "src/pages/secure/password/update";
import RegisterConfirm from "src/pages/secure/register/confirm";
import AccountCloseReqPage from "src/pages/secure/account-close";
import AccountCloseReqCompleted from "src/pages/secure/account-close/gone";

import Ticket from "src/pages/dashboard/ticket/Ticket";

import EventsPageWrapper from "src/pages/dashboard/events/wrapper";
import EventTicket from "src/pages/dashboard/events/EventTicket";
import EventTicketHolder from "src/pages/dashboard/events/EventTicketHolder";
import EventOverview from "src/pages/dashboard/events/overview";
// ----------------------------------------------------------------------
import { Page404, LoginPage } from "./elements";
import { PATH_DASHBOARD } from "./paths";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "secure",
      children: [
        {
          path: "password",
          children: [
            {
              element: <CompactLayout maxWidth={400} />,
              children: [
                { path: "reset", element: <ResetPasswordPage /> },
                { path: "update", element: <AuthNewPasswordForm /> },
              ],
            },
          ],
        },
        {
          path: "account",
          children: [
            {
              element: <CompactLayout maxWidth={400} />,
              children: [
                { path: "close", element: <AccountCloseReqPage /> },
                { path: "gone", element: <AccountCloseReqCompleted /> },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "register",
      children: [
        {
          element: <CompactLayout maxWidth={600} />,
          children: [
            { path: "", element: <CreateAccountPage /> },
            { path: "confirm", element: <RegisterConfirm /> },
          ],
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "profile",
          element: <UserProfilePage />,
          children: [
            {
              path: "ticket-wall",
              element: <TicketWallPage />,
              index: true,
            },
            {
              path: "friends",
              element: <ProfileFriendsPage />,
            },
            { path: "orgs", element: <ProfileOrgsPage /> },
          ],
        },
        {
          path: "/dashboard/profile",
          children: [
            {
              path: "edit",
              // element: <Typography>This page is in progress...</Typography>
              element: <EditUserPage />,
            },
          ],
        },
        {
          path: "orgs/:orgId",
          children: [
            { element: <Navigate to={PATH_DASHBOARD.orgs.overview} replace />, index: true },
            { path: "overview", element: <OrgOverview default_org_id="" /> },
            {
              path: "events",
              element: <OrgEvents default_org_id="" />,
              // children: [
              //   { path: ":eventId", element: <EventsPageWrapper  />

              // },
              // ],
            },
            {
              path: "events/:eventId",
              element: <EventsPageWrapper />,
              children: [
                { path: "overview", element: <EventOverview /> },
                { path: "tickets", element: <EventTicket /> },
                { path: "ticket-holders", element: <EventTicketHolder /> },
              ],
            },
            {
              path: "tickets",
              children: [
                {
                  path: "",
                  element: (
                    <>
                      <Helmet>
                        <title>Organisation Tickets | TX.IS</title>
                      </Helmet>
                      <OrgTickets allowEventFilter={false} />
                    </>
                  ),
                  index: true,
                },
                { path: ":ticketId", element: <Ticket /> },
              ],
            },
            {
              path: "ticket-holders",
              children: [
                {
                  path: "",
                  element: <OrgTicketHolders default_org_id="" allowEventFilter={false} />,
                  index: true,
                },
                {
                  path: ":ticketHolderId",
                  children: [
                    { path: "profile", element: <THPorfile /> },
                    { path: "tickets", element: <THTickets /> },
                    { path: "comms", element: <THComms /> },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
