import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type Event = {
  id: string;
  name: string;
  event_date?: string;
};
interface SelectedOrgState {
  orgId?: string;
  event?: Event;
  bears?: number;
  setOrdId: (id: string) => void;
  setStoreEvent: (obj?: Event) => void;
  setDefault: () => void;
}

export const useOrgStore = create<SelectedOrgState>()(
  devtools(
    persist(
      (set) => ({
        bears: 0,
        setOrdId: (orgId) => set(() => ({ orgId })),
        setStoreEvent: (event) => set(() => ({ event })),
        setDefault: () => set(() => ({ orgId: undefined, event: undefined })),
      }),
      {
        name: "org-store",
      }
    )
  )
);
