import { useLocation, matchPath } from "react-router-dom";

// ----------------------------------------------------------------------
type Props = {
  path: string;
  deep?: boolean;
  pattern?: string;
};
type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export default function useActiveLink({ path, deep = true, pattern }: Props): ReturnType {
  const { pathname } = useLocation();
  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;
  const patternActive = pattern ? !!matchPath(pattern, pathname) : false;

  return {
    // eslint-disable-next-line no-nested-ternary
    active: pattern ? patternActive : deep ? deepActive : normalActive,
    isExternalLink: path.includes("http"),
  };
}
